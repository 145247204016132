import { useState } from "react";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const DrillEntry = ({skillsList, drill, setEditing, remove}) => {

    const [state, setState] = useState(false);

    var thisDrill = {
        ...drill,
        skills: drill.drill.skills.map((skill) => {
            return skillsList.filter((value) => value.id === skill)[0];
        })
    };

    return (
        <div className='entry' style={{position: 'relative', border: '2px solid white', borderRadius: '10px', padding: '5px', margin: '5px 0'}}>
            <h3>{drill.name + ':'}</h3>
            <IconButton style={{position: 'absolute', top: '5px', right: '5px', padding: '0'}} aria-label="delete" size="large" alt='delete' onClick={() => (remove(drill.drill.drill_id))}>
                <DeleteIcon color="black" fontSize="inherit" />
            </IconButton>
            <IconButton style={{position: 'absolute', top: '5px', right: '30px', padding: '0'}} aria-label="edit" size="large" alt='edit' onClick={() => setEditing(drill)}>
                <EditIcon fontSize="inherit" />
            </IconButton>
            
            <p>{'Length: ' + drill.drill.length + ', No. of repetitions: ' + drill.drill.repetitions}</p>
            {state && <>
                <h4>Skills:</h4>
                <ol>
                    {thisDrill.skills.map((skill, index) => {
                        return <li key={index}><p style={{textAlign: 'start', marginLeft: '12%'}}>{(index + 1) + ', ' + skill.name}</p></li>
                    })}
                </ol>
            </>}
            <Button style={{fontSize: '12px', color: '#000'}} onClick={() => setState(!state)}>{!state ? 'Show more' : 'Show less'}</Button>

        </div>
    )

}

export default DrillEntry;