import { useState } from "react";
import { TextField, Button, Autocomplete } from "@mui/material";
import Header from "../../../components/general/Header.tsx";

const AddConditioningCircuit = ({ circuitHolder, userCircuits, handleSubmit, handleCancel }) => {

    const [circuit, setCircuit] = useState({
        ...circuitHolder,
        circuit_id: userCircuits[0].id,
        title: userCircuits[0].title
    });

    const handleCircuitChange = (e, newCircuit) => {
        setCircuit({
            ...circuit,
            circuit_id: newCircuit.id,
            title: newCircuit.title
        });
    }

    const handleSetsChange = (sets) => {
        setCircuit({
            ...circuit,
            sets: sets
        });
    }

    return (<>
        <Header title={'Add Circuit'} />

        <form className="new-entry">

            <Autocomplete style={{marginTop: '10px'}} 
                options={userCircuits} 
                getOptionLabel={(option) => option.title} 
                isOptionEqualToValue={() => true}
                value={userCircuits.filter(item => item.id === circuit.circuit_id)[0]}
                onChange={handleCircuitChange}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        {option.title}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params} label={"Search or select a circuit"} variant="outlined" />
                )}
            />

            <div className="hydration-buttons" style={{margin: '5% 20% 5% 5%'}}>
                <p>Sets: </p>
                <Button variant="outlined" size="medium" onClick={() => handleSetsChange(circuit.sets > 1 ? circuit.sets - 1 : 1)}>-</Button>
                <p>{circuit.sets}</p>
                <Button variant="outlined" size="medium" onClick={() => handleSetsChange(circuit.sets + 1)}>+</Button>
            </div>
        
            <div className='bottom-buttons'>
                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    disabled={circuit.circuit_id === null}
                    onClick={() => handleSubmit(circuit)}
                    variant="contained"
                >
                    Save Circuit
                </Button>

                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>     

        </form>

    </>)
};

export default AddConditioningCircuit;