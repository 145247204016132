import '../../../style/Diary.css';
import { IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from 'react';

const DiaryEntry = ({sessionStatus, entry, discipline, index, remove, setEditing, addRep, duplicate}) => {
    const [state, setState] = useState(false);

    return (
        <div className='entry' style={{position: 'relative', border: '2px solid white', borderRadius: '10px', padding: '5px', margin: '5px 0'}}>
            <h3>{(index) + ', ' + entry.objective.type + ':'}</h3>
            {sessionStatus === 'active' && <>
                <IconButton style={{position: 'absolute', top: '5px', right: '5px', padding: '0'}} aria-label="delete" size="large" alt='delete' onClick={() => (remove(entry.id))}>
                    <DeleteIcon color="black" fontSize="inherit" />
                </IconButton>
                <IconButton style={{position: 'absolute', top: '5px', right: '30px', padding: '0'}} aria-label="edit" size="large" alt='edit' onClick={() => setEditing(entry)}>
                    <EditIcon fontSize="inherit" />
                </IconButton>

                {entry.objective.type !== 'new-skill' && <>
                    <IconButton style={{position: 'absolute', top: '35px', right: '5px', padding: '0'}} aria-label="edit" size="large" alt='edit' onClick={() => duplicate(entry)}>
                        <ContentCopyIcon fontSize="inherit" />
                    </IconButton>

                    {/* Only show if already multiple reps. hide for new skills*/}
                    {(entry.repetitions > 1) &&
                        <IconButton style={{position: 'absolute', top: '60px', right: '5px', padding: '0'}} aria-label="edit" size="large" alt='edit' onClick={() => addRep(entry)}>
                            <PlusOneIcon fontSize="inherit" />
                        </IconButton>
                    }
                </>}
            </>}

            <p>
                {(entry.repetitions > 1 ? (entry.repetitions + 'x ') : '') + entry.objective.name + (entry.objective.type === 'section' ? (', skills ' + entry.objective.parent_first_skill + ' to ' + entry.objective.parent_last_skill) : '')}
            </p>


            {state && <>
                {(entry.total_tof > 0) && <>
                    <h4>{(entry.time_method === 'stopwatch' ? 'Stopwatch time: ' : 'Time of Flight: ') + entry.total_tof + 's'}</h4>
                </>}
                {(entry.total_hd > 0) && <>
                    <h4>{'HD: ' + entry.total_hd}</h4>
                </>}
                {(entry.execution > 0) && <>
                    <h4>{'Execution: ' + entry.execution}</h4>
                </>}
                {(entry.landing_penalty != null && entry.landing_penalty !== 999) && <>
                    <h4>{'Landing Deduction: ' + entry.landing_penalty}</h4>
                </>}
                {(entry.feedback !== null && entry.feedback !== '') && <>
                    <h4>Feedback:</h4>
                    <p>{entry.feedback}</p>
                </>}
                {(entry.self_reflection !== null && entry.self_reflection !== '') && <>
                    <h4>Self Reflection:</h4>
                    <p>{entry.self_reflection}</p>
                </>}
                <h4>{'Completed skills: ' + entry.total_completed_skills}</h4>
                <h4>{'Difficulty' + (entry.repetitions > 1 ? ' (each)' : '') + ': ' + entry.total_difficulty}</h4>
            </>}

            <Button style={{fontSize: '12px', color: '#000'}} onClick={() => setState(!state)}>
                {!state ? 'Show more' : 'Show less'}
            </Button>
            
        </div>
    )
}

export default DiaryEntry;