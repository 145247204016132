import { useState, useEffect } from "react";
import { Tab, Tabs, Box } from "@mui/material";
import NewSkills from "./components/NewSkills";
import PlaySkills from "./components/PlaySkills";
import Header from "../../components/general/Header.tsx";
import TabPanel, { a11yProps } from "../../components/general/TabPanel.js";

const DeploymentType = 'Live';

const Skills = ({user}) => {

    const [skillsList, setSkillsList] = useState([]);
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (DeploymentType === "Live") {
            fetch(process.env.REACT_APP_API_HOST + '/api/skills/trampoline')
                .then(response => response.json())
                .then(skillsList => {
                    setSkillsList(skillsList.filter(val => (val.id > 2)));
                })
                .catch(error => console.error('Error fetching all skills:', error))
        }
    }, [setSkillsList]); 

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Header title={'Skills'} />    
                    
            <Box sx={{ width: '100%', backgroundColor: 'white', borderRadius: '10px', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="New Skills" sx={{width: '50%', fontSize: '1.4rem'}} {...a11yProps(0)} />
                    <Tab label="Play Skills" sx={{width: '50%', fontSize: '1.4rem'}} {...a11yProps(1)} />
                </Tabs>              
            </Box>
            
            <TabPanel value={value} index={0}>
                <NewSkills skills={skillsList} user={user} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PlaySkills user={user} />
            </TabPanel>

        </>
    )

};

export default Skills;