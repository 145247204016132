import { useState } from "react";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const RoutineEntry = ({skillsList, routine, setEditing, remove}) => {

    const [state, setState] = useState(false);

    var thisRoutine = {
        ...routine,
        skills: routine.routine.skills.map((skill) => {
            return skillsList.filter((value) => value.id === skill)[0];
        })
    };

    return (
        <div className='entry' style={{position: 'relative', border: '2px solid white', borderRadius: '10px', padding: '5px', margin: '5px 0'}}>
            <h3>{routine.name + ':'}</h3>

            <IconButton style={{position: 'absolute', top: '5px', right: '5px', padding: '0'}} aria-label="delete" size="large" alt='delete' onClick={() => (remove(routine.id))}>
                <DeleteIcon color="black" fontSize="inherit" />
            </IconButton>
            <IconButton style={{position: 'absolute', top: '5px', right: '30px', padding: '0'}} aria-label="edit" size="large" alt='edit' onClick={() => setEditing('trampoline', routine)}>
                <EditIcon fontSize="inherit" />
            </IconButton>
            
            <p>{'Difficulty: ' + routine.routine.difficulty}</p>

            {state && <>
                <h4>Skills:</h4>
                <ol>
                    {thisRoutine.skills.map((skill, index) => {
                        return <li key={index}><p style={{textAlign: 'start', marginLeft: '12%'}}>{(index + 1) + ', ' + skill.name}</p></li>
                    })}
                </ol>
            </>}

            <Button style={{fontSize: '12px', color: '#000'}} onClick={() => setState(!state)}>
                {!state ? 'Show more' : 'Show less'}
            </Button>

        </div>
    )

}

export default RoutineEntry;