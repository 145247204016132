import { Link } from "react-router-dom";
import styles from '../style/userhome.module.css';

const UserHome = ({user}) => {

    return (
        <>
            <div className={styles["page-title-row"]}>
                <div className={`${styles["col-6"]} ${styles["img-container"]}`}>
                    <img src="../img/tramp-logo-s.png" alt="" />
                </div>

                <div className={`${styles["col-6"]} ${styles["title-container"]}`}>
                    <h1>MENU</h1>
                </div>
            </div>
            <h3 style={{textAlign:"center", margin: "5% 0"}}>Welcome, {user.username}</h3>
            <main className="menu-grid">
                <div className={styles.row}>
                    <Link to="/diary"><div className={styles["menu-btn"]}><p>Diary</p></div></Link>
                    <Link to="/events"><div className={styles["menu-btn"]}><p>Results</p></div></Link>
                    <Link to="/goals"><div className={styles["menu-btn"]}><p>Goals</p></div></Link>
                </div>
                <div className={styles.row}>
                    <Link to="/routines"><div className={styles["menu-btn"]}><p>Routines</p></div></Link>
                    <Link to="/drills"><div className={styles["menu-btn"]}><p>Drills</p></div></Link>  
                    <Link to="/skills"><div className={styles["menu-btn"]}><p>Skills</p></div></Link>
                </div>
                <div className={styles.row}>
                    <Link to="/conditioning"><div className={styles["menu-btn"]}><p>Conditioning</p></div></Link>                   
                    <Link to="/data"><div className={styles["menu-btn"]}><p>Insights</p></div></Link>
                    <Link to="/resources"><div className={styles["menu-btn"]}><p>Resources</p></div></Link>
                </div>
                {/* <div className={styles.row}>
                    <Link><div className={styles["menu-btn"]}><p>TBC</p></div></Link>
                    <Link to="/settings"><div className={styles["menu-btn"]}><p>Settings</p></div></Link> 
                    <Link onClick={signOut}><div className={styles["menu-btn"]}><p>Logout</p></div></Link>
                </div> */}
            </main>
        </>
    )
  };
  
  export default UserHome;