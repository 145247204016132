import { useState, useEffect } from "react";
import { ToggleButton, ToggleButtonGroup, TextField, FormControl, InputLabel, Select, MenuItem, Button, Autocomplete } from "@mui/material";
import axios from 'axios';

const AddTrampolineEntry = ({objectives, entryHolder, handleNewEntry, handleCancel}) => {

    // Initialise the entry, be it blank or editing an existing one.
    const [entry, setEntry] = useState(entryHolder);
    const isNewSkillEdit = entryHolder.objective.type === 'new-skill';
    
    const initialRecorded = [];
    entryHolder.total_tof > 0 && initialRecorded.push('tof');
    entryHolder.total_hd > 0 && initialRecorded.push('hd');
    entryHolder.execution > 0 && initialRecorded.push('execution');
    const [wasRecorded, setWasRecorded] = useState(initialRecorded);   
    const [isBothRecorded, setBothRecorded] = useState(wasRecorded.includes('hd') && wasRecorded.includes('execution'));
    const [leftInputStyle, setLeftInputStyle] = useState(isBothRecorded ? {width: "49.5%", margin: "10px 1% 0 0"} : {width: "100%", marginTop: "10px"});
    const [rightInputStyle, setRightInputStyle] = useState(isBothRecorded ? {width: "49.5%", marginTop: "10px"} : {width: "100%", marginTop: "10px"});
    
    // filter list of all objectives by type for current entry.
    const [typedObjectives, setTypedObjectives] = useState(objectives.filter((objective) => (objective.type === entry.objective.type)));
    
    // Was this a single attempt or multiple
    const [singleOrMultiple, setSingleOrMultiple] = useState(entryHolder.repetitions === 1 ? 'single' : 'multiple');

    useEffect(() => {
        setBothRecorded(wasRecorded.includes('hd') && wasRecorded.includes('execution'));
        setLeftInputStyle(isBothRecorded ? {width: "49.5%", margin: "10px 1% 0 0"} : {width: "100%", marginTop: "10px"});
        setRightInputStyle(isBothRecorded ? {width: "49.5%", marginTop: "10px"} : {width: "100%", marginTop: "10px"});
    }, [wasRecorded, isBothRecorded]);

    // For routine section, get sectionID and difficulty
    function getSectionIdAndDifficulty(routineId, firstSkill, sectionLength) {

        const routineArray = routineId.split('-').map(Number);
      
        const startIndex = firstSkill - 1; // Adjust to 0-based index
        const endIndex = startIndex + sectionLength;
      
        const sectionArray = routineArray.slice(startIndex, endIndex);

        const difficulty = parseFloat(sectionArray.reduce((total, current) => total + objectives.filter((value) => (value.type === 'skill' && parseInt(value.id) === current))[0].difficulty, 0).toFixed(1))

        const sectionId = sectionArray.join('-');
        
        return [sectionId, difficulty];
    }

    // Total planned skills for each type
    function getTotalSkills(type, objId) {
        if (type === 'routine') {
            return 10;
        }
        else if (type === 'skill' || type === 'new-skill') {
            return 1;
        }
        else if (type === 'drill') {
            const drillArray = objId.split('-').map(Number)
            return parseInt(drillArray[0] * drillArray[1]);
        }
        else if (type === 'custom') {
            return entry.total_skills;
        }
        return 0;
    }

    // Get total dd when not all skills completed
    function getCompletedSkillsDifficulty(type, id, completed) {
        const objectiveArray = id.split('-').map(Number);
        var movesCompleted = [];
        
        if (type === 'drill') {
            const numRepetitions = parseInt(objectiveArray[1], 10);
            const skillIds = objectiveArray.slice(2);
   
            for (let i = 0; i < numRepetitions; i++) {
                movesCompleted = movesCompleted.concat(skillIds);
            }
        }
        else {
            movesCompleted = objectiveArray;
        }
        movesCompleted = movesCompleted.slice(0, completed);

        return parseFloat(movesCompleted.reduce((total, current) => total + objectives.find((value) => value.id === current.toString()).difficulty, 0).toFixed(1))
    };

    // When entry type changes, change list of objectives and update objectives property
    const handleEntryTypeChange = (e) => {

        if (e.target.value === 'section') {
            setTypedObjectives(objectives.filter((objective) => (objective.type === 'routine')));
            setEntry((entry) => ({
                ...entry, 
                objective: {type: e.target.value, id: '', name: '', parent_id: ''},
                total_skills: 5,
                total_completed_skills: 5,
                completed: true,
                new_skill_phase: ""  // reset this as only applicable to new skills
            }));
        }
        else if (e.target.value === 'custom') {
            setEntry((entry) => ({
                ...entry, 
                objective: {type: e.target.value, id: '', name: ''},
                total_skills: 5,
                total_completed_skills: 5,
                completed: true,
                repetitions: 1,     ///// currently can only hae one rep of custom
                total_difficulty: 0, // reset as manually entered
                new_skill_phase: "" // reset as only for new skills
            }));
        }
        else if (e.target.value === 'new-skill' && entry.new_skill_phase.length === 0) {
            setTypedObjectives(objectives.filter((objective) => (objective.type === 'skill')));
            setEntry({
                ...entry,
                objective: {type: e.target.value, id: '', name: ''},
                total_skills: entry.repetitions,
                total_completed_skills: entry.repetitions,
                completed: true,
                execution: 0, // no ex, hd or tof for new skills so reset
                total_hd: 0,
                total_tof: 0,
                new_skill_phase: 'to_mat'
            });
        }
        else {   
            setTypedObjectives(objectives.filter((objective) => (objective.type === e.target.value)));
            setEntry((entry) => ({
                ...entry, 
                objective: {type: e.target.value, id: '', name: ''},
                completed: true,
                new_skill_phase: ""
            }));
        }

        if (e.target.value !== 'routine') {
            setWasRecorded(wasRecorded.filter(item => item !== 'execution'));
        }      

        if (e.target.value === 'custom' || e.target.value === 'new-skill') { // do I need to do this for new skill?
            setSingleOrMultiple('single');
        }
        
    };

    const handleObjectiveChange = (e, newValue) => {
        if (newValue !== null) {
            if (entry.objective.type === 'section') {
                const [sectionId, difficulty] = getSectionIdAndDifficulty(newValue.id, 1, 5);
                console.log('difficulty: ' + difficulty); // just so difficulty isn't unused
                setEntry((entry) => ({ 
                    ...entry, 
                    objective: {type: 'section', id: sectionId, parent_id: newValue.id, parent_first_skill: 1, parent_last_skill: 5, name: (newValue.name + ' Section')},
                }));
            }
            else if (entry.objective.type === 'new-skill'){
                setEntry((entry) => ({ 
                    ...entry, 
                    objective: {type: 'new-skill', id: newValue.id, name: newValue.name},
                    total_skills: entry.repetitions,
                    total_completed_skills: entry.repetitions
                }));
            }
            else {
                let numSkills = getTotalSkills(newValue.type, newValue.id);
                setEntry((entry) => ({ 
                    ...entry, 
                    objective: {type: newValue.type, id: newValue.id, name: newValue.name},
                    total_skills: numSkills * entry.repetitions,
                    completed: true, // hopefully remove
                    total_completed_skills: numSkills * entry.repetitions
                }));
            }
        }
    };

    // First skills for routine section
    const handleFirstSkillChange = (event) => {
        const newValue = parseInt(event.target.value);
        var len;
        if (newValue >= entry.objective.parent_last_skill) {
            len = 2;
        } 
        else {
            len = entry.objective.parent_last_skill - newValue + 1;
        }
        const reps = entry.repetitions;
        const [sectionId, difficulty] = getSectionIdAndDifficulty(entry.objective.parent_id, newValue, len);
        console.log('difficulty: ' + difficulty); // stops complaint tht diff if unused
        const newObj = {
            ...entry.objective,
            id: sectionId,
            parent_first_skill: newValue,
            parent_last_skill: newValue >= entry.objective.parent_last_skill ? newValue + 1 : entry.objective.parent_last_skill
        };
        setEntry({
            ...entry,
            objective: newObj,
            total_skills: reps * len,
            total_completed_skills: reps * len,
            completed: true
        });
    };

    // Last skills for routine section
    const handleLastSkillChange = (val) => {
        const newValue = parseInt(val);
        const newLen = newValue - entry.objective.parent_first_skill + 1;
        const [sectionId, difficulty] = getSectionIdAndDifficulty(entry.objective.parent_id, entry.objective.parent_first_skill, newLen);
        console.log('difficulty: ' + difficulty); // Stops complaint of diff not being used
        const newObj = {
            ...entry.objective,
            id: sectionId,
            parent_last_skill: newValue
        };
        const reps = entry.repetitions;
        setEntry({
            ...entry,
            total_skills: reps * newLen,
            total_completed_skills: reps * newLen,
            objective: newObj,
            completed: true
        });
    };

    const handleFreetypeObjectiveChange = (e) => {
        setEntry((entry) => ({ 
            ...entry, 
            objective: {
                type: 'custom', 
                id: 'custom', 
                name: e.target.value
            }
        }));
    }

    // Total number of skills for custom objective input
    const handleNumSkillsChange = (e) => {
        const reps = entry.repetitions;
        setEntry({
            ...entry,
            total_skills: parseInt(e.target.value) * reps,
            total_completed_skills: parseInt(e.target.value) * reps, 
            completed: true
        });
    };   
    
    const handleSingleOrMultipleChange = (e) => {
        
        if (entry.objective.type !== 'section') {
            const skillsPerAttempt = getTotalSkills(entry.objective.type, entry.objective.id)
            setEntry({
                ...entry,
                repetitions: 1,
                completed: true,
                total_completed_skills: skillsPerAttempt,
                total_skills: skillsPerAttempt,
                execution: 0,
                total_tof: 0,
                total_hd: 0
            });
        }
        else {
            const numSkills = entry.objective.parent_last_skill - entry.objective.parent_first_skill + 1;
            setEntry({
                ...entry,
                repetitions: 1,
                total_completed_skills: numSkills,
                total_skills: numSkills,
                completed: true,
                execution: 0,
                total_tof: 0,
                total_hd: 0
            })
        }
        setWasRecorded([]);
        setSingleOrMultiple(e.target.value);
    }

    const handleRepetitionChange = (e) => {
        let reps = parseInt(e.target.value.trim());
        reps = isNaN(reps) ? 0 : reps;

        if (reps < 0) {
            reps = 0;
        }

        const skillsPerAttempt = entry.objective.type === 'section' ? (entry.objective.parent_last_skill - entry.objective.parent_first_skill + 1) : getTotalSkills(entry.objective.type, entry.objective.id);
        setEntry({
            ...entry,
            repetitions: String(reps),
            total_completed_skills: reps * skillsPerAttempt,
            total_skills: reps * skillsPerAttempt
        });
    };

    // Successful / unsuccessful toggle
    const handleCompletedChange = () => {
        setEntry({
            ...entry,
            completed: !entry.completed,
            total_completed_skills: !entry.completed ? entry.total_skills : entry.total_skills - 1 //if complete use all skills
        });
    };

    const handleCompletedSkillsChange = (e) => {
        
        setEntry({
            ...entry,
            total_completed_skills: parseInt(e.target.value)
        });
    }; 

    // Tof, Ex and HD toggles
    const handleRecordedChange = (e, newRecorded) => {
        let newEntry = {...entry};
        if (!newRecorded.includes('execution')){
            newEntry = {
                ...newEntry,
                execution: 0
            };
        }
        if (!newRecorded.includes('hd')){
            newEntry = {
                ...newEntry,
                total_hd: 0
            };
        }
        if (!newRecorded.includes('tof')){
            newEntry = {
                ...newEntry,
                total_tof: 0,
                time_method: 'tof'
            };
        }
        setEntry(newEntry);
        setWasRecorded(newRecorded);
    };

    const handleFloatChange = (fieldName, e, min, max) => {
        let newVal = parseFloat(e.target.value.trim());
        newVal = isNaN(newVal) ? 0 : newVal;

        if (min !== 'none' && newVal < min) {
            newVal = min;
        } else if ( max !== 'none' && newVal > max) {
            newVal = max;
        }

        if (fieldName === 'total_difficulty') {     // are these two the same thing? If not needed?
            setEntry((entry) => ({ 
                ...entry, 
                [fieldName]: String(newVal)
            }));
        }
        else {
            setEntry((entry) => ({ ...entry, [fieldName]: String(newVal) }));
        }
    };

    // Handle changes to both feedback and self-reflection fields
    const handleFieldChange = (fieldName, e) => {
        if (e.target.value === '') {
            setEntry((entry) => ({ ...entry, [fieldName]: null }));
        } else {
            setEntry((entry) => ({ ...entry, [fieldName]: e.target.value }));
        }
    };

    const submitHandler = () => {
        const reps = entry.repetitions < 1 ? 1 : parseInt(entry.repetitions);
        const diff = entry.objective.type === 'custom' ? entry.total_difficulty : getCompletedSkillsDifficulty(entry.objective.type, entry.objective.id, entry.total_completed_skills);

        setEntry((prevEntry) => {
            const updatedEntry = {
                ...prevEntry, 
                execution: parseFloat(prevEntry.execution), 
                total_tof: parseFloat(prevEntry.total_tof), 
                total_hd: parseFloat(prevEntry.total_hd),
                repetitions: reps,
                total_difficulty: parseFloat(diff),
                total_load: parseFloat((diff + entry.total_completed_skills + entry.total_inbounces) * reps)
            };
    
            // Handle the patch request if required
            if (entryHolder.objective.id === '' && updatedEntry.objective.type === 'new-skill' && entryHolder.objective.id === '') {
                const newSkillPatch = {
                    user_id: updatedEntry.user_id,
                    skill_id: parseInt(updatedEntry.objective.id),
                    [entry.new_skill_phase]: parseInt(updatedEntry.repetitions)
                };
    
                axios.patch(process.env.REACT_APP_API_HOST + `/api/new-skills`, newSkillPatch, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .catch(error => {
                    console.error(`Error updating new skill details:`, error);
                }); 
            }

            handleNewEntry(updatedEntry);
            return updatedEntry;
        });
    };
    
    return (
        <form className="new-entry">

            {/* Objective type dropdown */}
            <FormControl fullWidth style={{marginTop: '10px'}}>
                <InputLabel id="objective-type-label">Entry Type</InputLabel>
                <Select
                    labelId="objective-type-label"
                    label="Entry Type"
                    id="objective-type"
                    value={entry.objective.type}
                    onChange={handleEntryTypeChange}
                    disabled={isNewSkillEdit}
                >                    
                    <MenuItem value={'routine'}>Routine</MenuItem>
                    <MenuItem value={'section'}>Routine Section</MenuItem> 
                    <MenuItem value={'drill'}>Drill</MenuItem>
                    <MenuItem value={'skill'}>Single Skill</MenuItem> 
                    <MenuItem value={'new-skill'}>Learning New Skill</MenuItem>                 
                    <MenuItem value={'custom'}>Custom</MenuItem>
                </Select>
            </FormControl>

            {/* Objective dropdown */} 
            {entry.objective.type !== 'custom' &&
                <Autocomplete style={{marginTop: '10px'}} 
                    options={typedObjectives} 
                    getOptionLabel={(option) => option.name} 
                    isOptionEqualToValue={() => true}
                    value={entry.objective}
                    onChange={handleObjectiveChange}
                    disabled={isNewSkillEdit}
                    renderInput={(params) => (
                        <TextField {...params} label={"Search or select a " + entry.objective.type} variant="outlined" />
                    )}
                />
            }

            {/* Section objective type fields */}
            {(entry.objective.type === 'section' && entry.objective.parent_id !== '') && <>
                {/* First and last skill */} 
                <FormControl style={{ width: "calc(50% - 3px)", marginTop: "10px", marginRight: "3px" }}>
                    <InputLabel id="first-skill-label">First Skill #</InputLabel>
                    <Select
                        labelId="first-skill-label"
                        label="First skill #"
                        id="first-skill"
                        variant="outlined"
                        value={entry.objective.parent_first_skill}
                        onChange={handleFirstSkillChange}
                    >
                        {[...Array(9).keys()].map((number) => (
                            <MenuItem key={number} value={number + 1}>{number + 1}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl style={{ width: "calc(50% - 3px)", marginTop: "10px", marginLeft: "3px" }}>
                    <InputLabel id="last-skill-label">Last skill #</InputLabel>
                    <Select
                        labelId="last-skill-label"
                        label="Last skill #"
                        id="last-skill"
                        variant="outlined"
                        value={entry.objective.parent_last_skill} // Previously entry.objective.parent_first_skill + entry.total_skills - 1
                        onChange={(e) => (handleLastSkillChange(e.target.value))}
                    >
                        {[...Array(10 - entry.objective.parent_first_skill).keys()].map((number) => number + entry.objective.parent_first_skill + 1).map((option) => (
                            <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </>}


            {/* Custom objective type fields */} 
            {entry.objective.type === 'custom' && <>
                {/* Custom objective textbox */} 
                <TextField fullWidth style={{ marginTop: "10px" }}
                    type="text"
                    id="freetype-objective"
                    label="Exercise objective"
                    variant="outlined"
                    value={entry.objective.name}
                    onChange={handleFreetypeObjectiveChange}
                />
            
                {/* Number of skills */} 
                <FormControl style={{ width: "calc(50% - 3px)", margin: "10px 3px 0 0" }}>
                    <InputLabel id="number-skills-label">Number of Skills</InputLabel>
                    <Select
                    labelId="number-skills-label"
                        label="Number of Skills"
                        id="number-skills"
                        variant="outlined"
                        value={entry.total_skills}
                        onChange={handleNumSkillsChange}
                    >
                        {[...Array(10).keys()].map((number) => (
                            <MenuItem key={number} value={number + 1}>{number + 1}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* difficulty number textbox */} 
                <TextField style={{ width: "calc(50% - 3px)", margin: "10px 0 0 3px" }}
                    type="number"
                    id="difficulty"
                    label="Total Difficulty"
                    variant="outlined"
                    value={entry.total_difficulty}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => handleFloatChange('total_difficulty', e, 0, 'none')}
                    onChange={(e) => handleFieldChange('total_difficulty', e)}
                />
            </>}

            {entry.objective.id !== "" && <>
                {/* If single or multiple attempts */}
                {(entry.objective.type !== 'custom' && entry.objective.type !== 'new-skill') &&
                    <ToggleButtonGroup
                        fullWidth
                        style={{marginTop: '10px'}}
                        color="primary"
                        value={singleOrMultiple}
                        exclusive
                        onChange={handleSingleOrMultipleChange}
                        aria-label="Single attempt or multiple"
                    >
                        <ToggleButton color="primary" value={'single'} aria-label="single">Single Attempt</ToggleButton>
                        <ToggleButton color="primary" value={'multiple'} aria-label="multiple">Multiple</ToggleButton>
                    </ToggleButtonGroup>
                }

                {singleOrMultiple === 'multiple' &&
                    <TextField fullWidth style={{ marginTop: "10px" }}
                        type="number"
                        id="repetitions"
                        label="Repetitions"
                        variant="outlined"
                        value={entry.repetitions}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleRepetitionChange}
                    />
                }

                {/* New skills */}
                {entry.objective.type === 'new-skill' && <>
                    {/* Skill development phase */}              
                    <FormControl fullWidth style={{width: 'calc(50% - 3px)', margin: '10px 3px 0 0'}}>
                    <InputLabel id="development-phase-label">Development Phase</InputLabel>
                    <Select
                        labelId="development-phase-label"
                        label="Development phase"
                        id="development-phase"
                        value={entry.new_skill_phase}
                        onChange={(e) => handleFieldChange('new_skill_phase', e)}
                        disabled={isNewSkillEdit}
                    >                    
                        <MenuItem value={'in_rig'}>In rig</MenuItem>
                        <MenuItem value={'to_pit'}>To pit</MenuItem>
                        <MenuItem value={'mat_with_catch'}>Mat with catch</MenuItem>
                        <MenuItem value={'to_mat'}>To mat</MenuItem>                   
                        <MenuItem value={'to_bed'}>To bed</MenuItem>
                        <MenuItem value={'in_combination'}>In combination</MenuItem>
                    </Select>
                    </FormControl>

                    {/* Repetitions */}
                    <TextField style={{ width: 'calc(50% - 3px)', margin: "10px 0 0 3px" }}
                        type="number"
                        id="repetitions"
                        label="Repetitions"
                        variant="outlined"
                        value={entry.repetitions}
                        disabled={isNewSkillEdit}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleRepetitionChange}
                    />
                </>}          

                {(singleOrMultiple !== 'multiple' && entry.objective.type !== 'new-skill') && <>
                    {/* Was turn successful */}
                    {(entry.objective.id !== '' && entry.objective.type !== 'custom') &&
                        <ToggleButtonGroup fullWidth style={{marginTop: '10px'}}
                            color="primary"
                            value={entry.completed}
                            exclusive
                            onChange={handleCompletedChange}
                            aria-label="success status"
                        >
                            <ToggleButton color="success" value={true} aria-label="complete">Complete</ToggleButton>
                            <ToggleButton color="error" value={false} aria-label="part complete">Part Complete</ToggleButton>
                        </ToggleButtonGroup>
                    }

                    {/* Number of completed skills if unsuccessful */}
                    {(entry.completed === false && entry.objective.type !== 'custom' && entry.objective.type !== 'skill') && 
                        <FormControl fullWidth style={{ marginTop: "10px"/*, width: "calc(50% - 3px)", marginRight: "3px"*/ }}>
                            <InputLabel id="completed-skills-label">Skills Completed</InputLabel>
                            <Select
                                labelId="completed-skills-label"
                                label="Skills Completed"
                                id="completed-skills"
                                variant="outlined"
                                value={entry.total_completed_skills}
                                onChange={handleCompletedSkillsChange}
                            >
                                {[...Array(entry.total_skills).keys()].map((number) => (
                                    <MenuItem key={number} value={number}>{number}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                
                    {/* Was tof, hd and execution recorded */} 
                    <ToggleButtonGroup
                        fullWidth
                        style={{marginTop: '10px'}}
                        color="primary"
                        value={wasRecorded}
                        onChange={handleRecordedChange}
                        aria-label="recorded scores"
                    >
                        <ToggleButton value="tof" aria-label="tof">Height</ToggleButton>
                        <ToggleButton value="hd" aria-label="hd">HD</ToggleButton>
                        {entry.objective.type === 'routine' &&
                            <ToggleButton value="execution" aria-label="execution">Execution</ToggleButton>
                        }
                    </ToggleButtonGroup>    
                    
                    {wasRecorded.includes('tof') && <>
                        {/* Time number textbox */} 
                        <TextField style={{ width: "49.5%", margin: "10px 1% 0 0" }}
                            type="number"
                            id="outlined-number"
                            label="Time"
                            variant="outlined"
                            value={entry.total_tof}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onBlur={(e) => handleFloatChange('total_tof', e, 0, entry.time_method === 'tof' ? 22 : 25)}
                            onChange={(e) => handleFieldChange('total_tof', e)}
                        />

                        {/* Timing method */}  
                        <FormControl style={{ width: "49.5%", marginTop: "10px" }}>
                            <InputLabel id="timing">Timing</InputLabel>
                            <Select
                                labelId="timing"
                                label="Method of Timing"
                                id="timing-select"
                                value={entry.time_method}
                                onChange={(e) => handleFieldChange('time_method', e)}
                            >
                                <MenuItem value={'tof'}>ToF</MenuItem>
                                <MenuItem value={'stopwatch'}>Stopwatch</MenuItem>
                            </Select>
                        </FormControl>
                    </>}

                    {/* HD number textbox */} 
                    {wasRecorded.includes('hd') &&                   
                        <TextField style={leftInputStyle}
                            type="number"
                            id="hd"
                            label="HD"
                            variant="outlined"
                            value={entry.total_hd}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            //onChange={(e) => handleFloatChange('total_hd', e, 0, 10)}
                            onBlur={(e) => handleFloatChange('total_hd', e, 0, 10)}
                            onChange={(e) => handleFieldChange('total_hd', e)}                        
                        />
                    }

                    {/* Execution number textbox */} 
                    {wasRecorded.includes('execution') &&                  
                        <TextField style={rightInputStyle}
                            type="number"
                            id="execution"
                            label="Execution"
                            variant="outlined"
                            value={entry.execution}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            //onChange={(e) => handleFloatChange('execution', e, 0, 20)}
                            onBlur={(e) => handleFloatChange('execution', e, 0, 20)}
                            onChange={(e) => handleFieldChange('execution', e)}
                        />
                    }
                </>}
            </>}

            {/* Feedback textbox */}    
            <TextField fullWidth style={{ marginTop: "10px" }}
                type="text"
                multiline
                rows={3}
                id="feedback"
                label="Coaches Feedback"
                variant="outlined"
                
                value={entry.feedback !== null ? entry.feedback : ''}
                onChange={(e) => handleFieldChange('feedback', e)}
            />

            {/* Self reflection textbox */}  
            <TextField fullWidth style={{ marginTop: "10px" }}
                type="text"
                id="reflection"
                label="Self Reflection"
                variant="outlined"
                rows={3}
                multiline
                value={entry.self_reflection !== null ? entry.self_reflection : ''}
                onChange={(e) => handleFieldChange('self_reflection', e)}
            />

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    disabled={entry.objective.id === '' || (entry.objective.type === 'custom' && entry.objective.name === '')}
                    onClick={submitHandler}
                    variant="contained"
                >
                    Save entry
                </Button>

                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>
            
        </form>
    )
};

export default AddTrampolineEntry;