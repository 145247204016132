import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingMessage from '../../../../components/LoadingMessage';
import DeleteConfirmationBox from '../../../../components/DeleteConfirmationBox';
import ConditioningTestSessionEntry from './ConditioningTestSessionEntry';
import AddConditioningTestSession from './AddConditioningTestSession';
import ConditioningTestSpeedDial from './ConditioningTestSpeedDial';
import CreateConditioningTest from './CreateConditioningTest';

const ConditioningTestSessions = ({ user }) => {

    const userId = user.userId;
    const navigate = useNavigate();
    const [sessions, setSessions] = useState([]);
    const [userTests, setUserTests] = useState([]);
    const [state, setState] = useState('loading');

    const [confirmModal, setConfirmModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);

    const emptySession = {
        user_id: userId,
        test_id: "",
        test_date: new Date().toISOString()
    }

    useEffect(() => {
        fetch(process.env.REACT_APP_API_HOST + `/api/conditioning/sessions/tests/${userId}`)
        .then(response => response.json())
        .then(data => {
            if (data !== null) {
                setSessions(data);
            }
        })
        .catch(error => console.error('Error fetching conditioning test sessions:', error));

        fetch(process.env.REACT_APP_API_HOST + `/api/conditioning/tests/${userId}`)
        .then(response => response.json())
        .then(data => {
            if (data !== null) {
                setUserTests(data);
            }
        })
        .catch(error => console.error('Error fetching user conditioning tests', error));

        setState('view');

    }, [userId]);

    const handleNewSession = (newSession) => {
        axios.post(process.env.REACT_APP_API_HOST + '/api/conditioning/sessions/tests', newSession, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            // Extract session ID from the response
            const { last_id } = response.data;
            const newEntryWithId = {...newSession, id: last_id};
            setSessions([newEntryWithId, ...sessions]);
            console.log(newEntryWithId);
            setState('view');
            navigate("/conditioning/test-session", {state: { session: newEntryWithId }});

        })
        .catch(error => {
            console.error('Error posting conditioning test session:', error);
        });  
    };

    const handleNewTest = (newTest) => {
        const test = {
            user_id: userId,
            title: newTest.title,
            test: newTest
        }

        axios.post(process.env.REACT_APP_API_HOST + '/api/conditioning/tests', test, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            const { last_id } = response.data;
            const newUserTest = {test_id: last_id, user_id: userId, title: newTest.title};
            setUserTests([newUserTest, ...userTests]);
            console.log(userTests, newUserTest);
            setState('view');
        })
        .catch(error => {
            console.error('Error posting conditioning test:', error);
        });  
        
    };

    const handleConfirmRemove = (id) => {
        setIdToDelete(id);
        setConfirmModal(true);
    };

    const handleDelete = (session_id) => {
        axios.delete(process.env.REACT_APP_API_HOST + `/api/conditioning/sessions/tests/${userId}/${session_id}`)
        .then(() => {
            setSessions(sessions.filter(item => item.id !== session_id));
            setConfirmModal(false);
            setIdToDelete(0);
        })
        .catch(error => {
            console.error('Error deleting conditioning session', error);
        });
    };

    const cancelEntry = () => {
        setState('view');
        setConfirmModal(false);
        setIdToDelete(0);
    };
    
    const addSession = () => {
        setState('entering');
    };

    const createTest = () => {
        setState('create')
    };

    // if (state === 'error') {
    //     return <LoadingMessage title={'Error'} />
    // }

    if (state === 'loading') {
        return <LoadingMessage title={'Conditioning'} />
    }

    return (
        <>
            {(state === 'view' && sessions.length && userTests.length) && <>
                <div className='entries-in-tabs'>
                    {sessions.map((session) => (
                        <ConditioningTestSessionEntry key={session.id} session={session} testTitle={userTests.find(test => test.test_id === session.test_id).title} remove={handleConfirmRemove} />
                    ))} 
                </div>

                <ConditioningTestSpeedDial 
                    iconOneAction={addSession} 
                    iconOneText={'Add Session'}
                    iconTwoAction={createTest} 
                    iconTwoText={'Create Test'}
                />
            </>}

            {state === 'entering' && 
                <AddConditioningTestSession sessionHolder={emptySession} conditioningTests={userTests} handleSubmit={handleNewSession} handleCancel={cancelEntry} />
            }
            
            {state === 'create' &&
                <CreateConditioningTest userId={userId} sessionHolder={emptySession} submitHandler={handleNewTest} handleCancel={cancelEntry} />
            }    

            {confirmModal === true && 
                <DeleteConfirmationBox message={"Are you sure you want to delete this session?"} handleConfirm={() => handleDelete(idToDelete)} handleCancel={cancelEntry}/>
            }
        </>
    )
}

export default ConditioningTestSessions;