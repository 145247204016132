import { useState } from "react";
import { Button } from "@mui/material";

const HydrationTracker = ({current, handleSave, handleCancel}) => {

    const [water, setWater] = useState(250);

    return (
        <div style={{backgroundColor: '#fff', borderRadius: '15px', position: 'absolute', top: '0', bottom: '10%', margin:'auto 0', height: 'fit-content', width: '100%'}}>
            <h2>Hydration Tracker</h2>
            <p style={{margin: '10% 0 5px'}}>How much have you drank?</p>
            <div className="hydration-buttons" style={{margin: '0 20% 10%'}}>
                <Button variant="outlined" size="medium" onClick={() => setWater(water - 50)}>-</Button>
                <p>{water}ml</p>
                <Button variant="outlined" size="medium" onClick={() => setWater(water + 50)}>+</Button>
            </div>
            <p>Consumed so far: {current}ml</p>

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={() => handleSave(water)}
                    variant="contained"
                >
                    Save
                </Button>

                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>
        </div>
    )
};

export default HydrationTracker;