import { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, CircularProgress } from "@mui/material";
import PlaySkillBrick from "./PlaySkillBrick";
import PlaySkillFocus from './PlaySkillFocus';


const PlaySkills = ({user}) => {

    const userId = user.userId;
    const [skillsList, setSkillsList] = useState([]);
    const [focusSkill, setFocusSkill] = useState(null);
    const [state, setState] = useState('view');

    useEffect(() => {
        fetch(process.env.REACT_APP_API_HOST + `/api/play-skills/${userId}`)
        .then(response => response.json())
        .then(data => {
            if (data !== null) {
                setSkillsList(data);
            }
        })
        .catch(error => console.error('Error fetching user play skills:', error));
    }, [userId]); 


    const handleFocus = (playSkill) => {
        setFocusSkill(playSkill);
        setState('focus');
    };

    const handleUpdate = (attempts, proficiency) => {
        const newSkill = {
            ...focusSkill,
            attempts: attempts,
            proficiency: proficiency
        }
        axios.post(process.env.REACT_APP_API_HOST + '/api/play-skills', newSkill, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(() => {
            setSkillsList(skillsList.map(skill => {
                if (skill.skill_id === focusSkill.skill_id) {
                    return newSkill;
                }
                else {
                    return skill;
                }
            }));
            setState('view');
        })
        .catch(error => {
            console.error('Error posting user play skill:', error);
        });  
    };

    const handleExit = () => {
        setState('view');
    };

    if (skillsList.length === 0) {
        return (
            <Box
                sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '65dvh',  // Full viewport height to center the content
                }}
            >
            <CircularProgress />
                <h3 style={{ textAlign: 'center', marginTop: '10px' }}>Loading...</h3>
            </Box>
        );
    }

    return (
        <>
            <div className='entries-in-tabs'>
                {skillsList.map(skill => (
                    <PlaySkillBrick className='entry' key={skill.skill_id} playSkill={skill} handleFocus={handleFocus} />
                ))}
            </div>

            {state === 'focus' && 
                <PlaySkillFocus playSkill={focusSkill} handleUpdate={handleUpdate} handleExit={handleExit} />
            }
        </>
    )

};

export default PlaySkills;
