import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AddIcon from '@mui/icons-material/Add';



export default function ConditioningTestSpeedDial({iconOneAction, iconOneText, iconTwoAction, iconTwoText}) {
  
  const actions = [
    { icon: <AddIcon color='secondary' onClick={iconOneAction} />, name: iconOneText },
    { icon: <PostAddIcon color='secondary' onClick={iconTwoAction} />, name: iconTwoText },
  ];
  
  return (
    <Box sx={{ height: 160, transform: 'translateZ(0px)', flexGrow: 1, position: 'absolute', bottom: '50px', right: '0' }}>
      <SpeedDial
        ariaLabel="Conditioning speeddial"
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
          />
        ))}
      </SpeedDial>
    </Box>
  );
}