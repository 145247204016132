import { Box, ToggleButton, ToggleButtonGroup, Button, IconButton /*TextField, Autocomplete*/ } from "@mui/material";
import {useState, useEffect} from 'react';
import axios from "axios";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import LogoutIcon from '@mui/icons-material/Logout';
import LoadingMessage from "../../components/LoadingMessage";
import Header from "../../components/general/Header.tsx";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Settings = ({userId, signOut}) => {

    const [hasChanged, setHasChanged] = useState(false);

    const disciplines = [
      'Trampoline',
      'DMT',
      'Tumbling'
    ];

    const [settings, setSettings] = useState(null);
  
    useEffect(() => {
      fetch(process.env.REACT_APP_API_HOST + `/api/user/${userId}`)
        .then(response => response.json())
        .then(data => {
          if (data !== null) {
            setSettings(data);
          }
        })
        .catch(error => console.error('Error fetching user settings:', error))
    }, [userId]);


    const handleDisciplineChange = (e) => {
      setHasChanged(true);
      setSettings({
        ...settings,
        disciplines: JSON.stringify(e.target.value)
      });

    };

    const handleGenderChange = (event) => {
      setHasChanged(true);
      setSettings({
        ...settings,
        gender: event.target.value
      });
    };

    const handleSubmit = () => {
      axios.patch(process.env.REACT_APP_API_HOST + '/api/user', settings, {
        headers: {
            'Content-Type': 'application/json',
        },
        })
        .catch(error => {
            console.error('Error patching user details:', error);
        }); 
    };


    if (settings === null) {
      return <LoadingMessage title={"Settings"} />;
    }

    return (
        <>
          <Header title={'Settings'} />

          <IconButton style={{position: 'absolute', top: '32px', right: '0', padding: '0', fontSize: "35px"}} aria-label="delete" size="large" alt='delete' onClick={signOut} >
              <LogoutIcon color="black" fontSize="inherit" />
          </IconButton>

          <form className="new-entry" style={{backgroundColor: '#fff', padding: '5px', borderRadius: '10px'}}>
        
            <h4>What disciplines do you train?</h4>
            <FormControl fullWidth style={{marginTop: '10px'}}>
              <InputLabel id="users-disciplines-select-label">Disciplines</InputLabel>
              <Select
                labelId="users-disciplines-select-label"
                id="users-disciplines-select"
                multiple
                value={JSON.parse(settings.disciplines)}
                onChange={handleDisciplineChange}
                input={<OutlinedInput id="users-disciplines-chip" label="Disciplines" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                    <Chip key={value} label={value} style={{fontSize: '1.3rem'}} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {disciplines.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={JSON.parse(settings.disciplines).indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
                ))}
              </Select>
            </FormControl>

            <h4 style={{marginTop: "10px"}}>What gender do you compete in?</h4>
            <ToggleButtonGroup
              fullWidth
              style={{margin: '10px 0 5px'}}
              color="primary"
              value={settings.gender}
              exclusive
              onChange={(e) => handleGenderChange(e)}
              aria-label="Active status"
            >
              <ToggleButton color="success" value={'male'} aria-label="male">Male</ToggleButton>
              <ToggleButton color="error" value={'female'} aria-label="female">Female</ToggleButton>
            </ToggleButtonGroup>


            <div className='bottom-buttons'>
              <Button
                style={{margin: "10px 5px 5px", width: '100px'}}
                disabled={JSON.parse(settings.disciplines).length === 0 || !hasChanged}
                onClick={handleSubmit}
                variant="contained"
              >
                Save
              </Button>
            </div>
          </form>
        </>
    );

};

export default Settings;
