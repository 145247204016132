import { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ResourceEntry from "./components/ResourceEntry";
import LoadingMessage from "../../components/LoadingMessage";

const Resources = () => {

    const [files, setFiles] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState('all');
    const [seriesFilter, setSeriesFilter] = useState('all');

    useEffect(() => {
        fetch(process.env.REACT_APP_API_HOST + '/api/resources/general')
        .then(response => response.json())
        .then(files => {
            // Set the data in the state variable
            setFiles(files);
        })
        .catch(error => console.error('Error fetching all skills:', error));
    }, [])

    if (files.length === 0) {
        return <LoadingMessage title={'Resources'} />
    };

    return (
        <div className="resource-links">
            <h2 style={{textAlign: 'center'}}>General Resources</h2>
            {/* <br /> */}
            <h4>Filters: </h4>
            <div style={{backgroundColor: '#fff', padding: '10px', borderRadius: '10px'}}>
                
                <FormControl style={{marginTop: '10px', width: '49%', marginRight: '2%'}}>
                    <InputLabel id="simple-select-label">Category</InputLabel>
                    <Select
                        labelId="simple-select-label"
                        id="demo-simple-select"
                        value={categoryFilter}
                        label="Category"
                        onChange={(e) => setCategoryFilter(e.target.value)}
                    >
                        <MenuItem value={'all'}>All</MenuItem>
                        <MenuItem value={'competition'}>Competition resources</MenuItem>
                        <MenuItem value={'training'}>Training resources</MenuItem>
                    </Select>
                </FormControl>
                {categoryFilter === 'competition' &&
                    <FormControl style={{marginTop: '10px', width: '49%'}}>
                        <InputLabel id="simple-select-label">Series</InputLabel>
                        <Select
                            labelId="simple-select-label"
                            id="demo-simple-select"
                            value={seriesFilter}
                            label="Series"
                            onChange={(e) => setSeriesFilter(e.target.value)}
                        >
                            <MenuItem value={'all'}>All</MenuItem>
                            <MenuItem value={'british'}>British</MenuItem>
                            <MenuItem value={'league'}>League</MenuItem>
                            <MenuItem value={'bucs'}>BUCS</MenuItem>
                            <MenuItem value={'neut'}>NEUT</MenuItem>
                        </Select>
                    </FormControl>
                }
            </div>
            <div className="general-resources" style={{paddingLeft: '10px'}}>
                <div className='entries' style={{top: '165px'}}>
                    {files.filter((item) => {
                        if (categoryFilter === 'all') {
                            return item;
                        }
                        else if (item.type === categoryFilter) {
                            return item;
                        }
                        else {
                            return false;
                        }
                        }).filter((item) => {
                            if (seriesFilter === 'all') {
                                return item;
                            }
                            else if (item.series === seriesFilter) {
                                return item;
                            }
                            else {
                                return false;
                            }
                        }).map((resource, index) => (
                        <ResourceEntry key={index} file={resource} />
                    ))} 
                </div>
            </div>
        </div>
    )

};

export default Resources;