import { useState } from "react";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const DmtResultsEntry = ({result, setEditing, remove}) => {

    const [state, setState] = useState(false);

    function ordinal_suffix_of(i) {
        let j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }

    let headline = 'Total Score: ' + result.total_score;
    if (result.position !== null && result.position > 0) {
        headline += ' | Position: ' + ordinal_suffix_of(result.position);
    }

    return (
        <div className='entry' style={{position: 'relative', border: '2px solid white', borderRadius: '10px', padding: '5px', margin: '5px 0'}}>
            <h3>{result.event + ':'}</h3>
            
            <IconButton style={{position: 'absolute', top: '5px', right: '5px', padding: '0'}} aria-label="delete" size="large" alt='delete' onClick={() => (remove(result.id))}>
                <DeleteIcon color="black" fontSize="inherit" />
            </IconButton>
            <IconButton style={{position: 'absolute', top: '5px', right: '30px', padding: '0'}} aria-label="edit" size="large" alt='edit' onClick={() => setEditing('dmt', result)}>
                <EditIcon fontSize="inherit" />
            </IconButton>
            
            <p>{headline}</p>

            {state && <>
                <h4>Breakdown:</h4>
                <p>Execution: {result.execution}</p>
                <p>Difficulty: {result.difficulty}</p>
                <p>Penalty: {result.penalty}</p>
                {(result.comment !== null && result.comment.length > 0) &&
                    <p>Comment: {result.comment}</p>
                }
            </>}
            
            <Button style={{fontSize: '12px', color: '#000'}} onClick={() => setState(!state)}>
                {!state ? 'Show more' : 'Show less'}
            </Button>

        </div>
    )
}

export default DmtResultsEntry;