import { useState } from "react";
import { TextField, InputLabel, MenuItem, Button, Select, FormControl, styled } from "@mui/material";
import Header from "../../../components/general/Header.tsx";
// import { Chip, Stack, Box } from '@mui/material';

// const RAGStatusSelector = ({ value, onChange }) => {
//     const colors = {
//         red: { label: 'Red', color: 'error' },
//         amber: { label: 'Amber', color: 'warning' },
//         green: { label: 'Green', color: 'success' },
//     };

//     return (
//         <Box display="flex" justifyContent="center">
//             <Stack direction="row" spacing={2}>
//                 {Object.entries(colors).map(([key, { label, color }]) => (
//                     <Chip
//                         key={key}
//                         label={label}
//                         color={color}
//                         variant="filled"
//                         onClick={() => onChange(key)}
//                         style={{ cursor: 'pointer' }}
//                     />
//                 ))}
//             </Stack>
//         </Box>
//     );
// };

const ragColors = {
    red: 'rgba(255, 0, 0, 0.3)', // Light translucent red
    amber: 'rgba(255, 223, 0, 0.3)', // Light translucent amber/orange
    green: 'rgba(0, 128, 0, 0.3)' // Light translucent green
};

const StyledSelect = styled(Select)(({ value }) => ({
    backgroundColor: value ? ragColors[value] : 'transparent',
}));

const inputStyle = {
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center', // Align text vertically in the center
    textAlign: 'center',
};

const AddGoalReview = ({reviewHolder, handleSubmit, handleCancel}) => {

    // Initialise the review, be it blank or editing an existing one.
    const [review, setReview] = useState(reviewHolder);

    // Handle changes to both feedback and self-reflection fields
    const handleFieldChange = (fieldName, e) => {

        if (e.target.value === '') {
            setReview((review) => ({ ...review, [fieldName]: '' }));
        } else {
            setReview((review) => ({ ...review, [fieldName]: e.target.value }));
        }
    };

    const handleDateChange = (date) => {
        setReview({
            ...review,
            review_date: date
        });
    };

    return (<>
        <Header title={'Add Goal Review'} />
        <form className="new-entry">

            {/* goal review */}
            <TextField fullWidth style={{ marginTop: "10px" }}
                type="text"
                id="review"
                multiline
                rows={5}
                label="Progress Review"
                variant="outlined"
                value={review.review || ''}
                onChange={(e) => handleFieldChange('review', e)}
            />

            {/* Date of review */}
            <TextField fullWidth
                type="date"
                id="review-date"
                label="Review Date"
                value={review.review_date.split('T')[0]}
                onChange={(e) => {handleDateChange(new Date(e.target.value).toISOString())}}
                InputLabelProps={{ shrink: true }}                
                style={{ marginTop: '15px' }} 
                inputProps={{ style: inputStyle }}                 
            />

            <FormControl fullWidth style={{ marginTop: '10px' }} >
                <InputLabel id="progress-label">Progress (RAG)*</InputLabel>
                <StyledSelect
                    labelId="progress-label"
                    label="Progress (RAG)"
                    variant="outlined"
                    value={review.rag || ""}
                    onChange={(e) => handleFieldChange('rag', e)}
                >
                    <MenuItem value={""}></MenuItem>
                    <MenuItem value={"red"} style={{ backgroundColor: ragColors.red }}>Red</MenuItem>
                    <MenuItem value={"amber"} style={{ backgroundColor: ragColors.amber }}>Amber</MenuItem>
                    <MenuItem value={"green"} style={{ backgroundColor: ragColors.green }}>Green</MenuItem>
                </StyledSelect>
            </FormControl>            

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    disabled={review.review === null || review.review.length === 0}
                    onClick={() => handleSubmit(review)}
                    variant="contained"
                >
                    Save review
                </Button>

                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>           
        </form>
        </>)

};

export default AddGoalReview