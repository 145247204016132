import { useState } from "react";
import { TextField, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import RoundDownToFiveMinutes from '../../../../components/utils/RoundTimeToFiveMinutes';
import Header from "../../../../components/general/Header.tsx";


const AddConditioningTestSession = ({sessionHolder, conditioningTests, handleSubmit, handleCancel}) => {

    const [session, setSession] = useState(sessionHolder);
    const [startTime, setStartTime] = useState(dayjs(RoundDownToFiveMinutes(new Date())));
    
    const handleFieldChange = (fieldName, e) => {
        if (e.target.value === '') {
            setSession({ ...session, [fieldName]: '' });
        } else {
            setSession({ ...session, [fieldName]: e.target.value });
        }
    };

    const handleDateChange = (field, date) => {
        setSession({
            ...session,
            [field]: date
        });
    };
    const preSubmit = () => {
        setSession((prev) => {
            const newSession = {
                ...prev,
                test_date: dayjs(prev.start_time)
                            .hour(startTime.hour())
                            .minute(startTime.minute())
                            .second(0)
                            .millisecond(0)
                            .format('YYYY-MM-DDTHH:mm:ssZ')
            };

            handleSubmit(newSession);
            return newSession
        })
    }

    return (<>
        <Header title={'Add Conditioning Test'} />

        <form className="new-entry">

            {/* Objective type dropdown */}
            <FormControl fullWidth style={{marginTop: '10px'}}>
                <InputLabel id="conditioning-test-label">Test</InputLabel>
                <Select
                    labelId="conditioning-test-label"
                    label="Test"
                    id="conditioning-test"
                    value={session.test_id ?? ''}
                    onChange={(e) => handleFieldChange('test_id', e)}
                >
                    {conditioningTests.map(test => (
                        <MenuItem key={test.test_id} value={test.test_id}>
                            {test.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TextField fullWidth style={{marginTop: '15px'}}
                label="Start Date"
                type="date"
                value={session.test_date.split('T')[0] || null}
                onChange={(e) => handleDateChange('test_date', new Date(e.target.value).toISOString())}
                InputLabelProps={{ shrink: true }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker 
                    label="Start time" 
                    value={startTime}
                    onChange={(time) => {setStartTime(time)}}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            // InputProps: {
                            // sx: {
                            //     fontSize: '16px', // Increase the font size for the input text
                            //     textAlign: 'center',
                            //     '& input': {
                            //         textAlign: 'center', // This centers the text inside the input field
                            //     },
                            // },
                            // },
                            InputLabelProps: {
                            shrink: true,
                            sx: {
                                fontSize: '12px', // Increase the font size for the label
                            },
                            },
                            sx: {
                                marginTop: '15px',   // Add margin-top of 10px
                            },
                        },
                    }}
                />
            </LocalizationProvider>

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    disabled={session.test_id === ''}
                    onClick={preSubmit}
                    variant="contained"
                >
                    Add Session
                </Button>

                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>     

        </form>
    </>)

};

export default AddConditioningTestSession;