import { Button, Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState } from "react";

const AddConditioningTestExercise = ({ exerciseList, handleSubmit, handleCancel}) => {

    const [exercise, setExercise] = useState({
        "exercise_id": 1,
        "exercise_type": "standard",
        "metric": "reps",
        "details": "",
        "reps": 1,
        "weight": 0,
        "duration": 0
    });

    const handleExerciseChange = (e, newValue) => {
        newValue !== null &&
            setExercise({
                ...exercise,
                exercise_id: newValue.id,
                exercise_type: newValue.type
            });
    }

    // const handleFloatChange = (fieldName, e, min, max) => {
    //     let newVal = parseFloat(e.target.value.trim());
    //     newVal = isNaN(newVal) ? 0 : newVal;

    //     if (min !== 'none' && newVal < min) {
    //         newVal = min;
    //     } else if ( max !== 'none' && newVal > max) {
    //         newVal = max;
    //     }

    //     setExercise((entry) => ({ ...entry, [fieldName]: String(newVal) }));
    // };

    const handleFieldChange = (fieldName, e) => {
        if (e.target.value === '') {
            setExercise({ ...exercise, [fieldName]: '' });
        } 
        else {
            setExercise({ ...exercise, [fieldName]: e.target.value });
        }
    };

    const preSubmit = () => {
        setExercise((prev) => {
            const finalEx = {
                ...exercise,
                duration: parseFloat(prev.duration),
                weight: parseFloat(prev.weight)
            }

            handleSubmit(finalEx);
            return finalEx
        });

    }

    return (
        <div style={{backgroundColor: '#fff', padding: '0 5px', border: "2px solid grey", borderRadius: '15px', position: 'absolute', top: '0', bottom: '10%', margin:'auto 0', height: 'fit-content', width: '100%', zIndex: '1000'}}>
            <h2>Add Exercise</h2>

            {/* Exercise */}
            <Autocomplete style={{marginTop: '10px'}} 
                options={exerciseList} 
                getOptionLabel={option => option.name} 
                isOptionEqualToValue={() => true}
                value={exerciseList.filter(ex => ex.id === exercise.exercise_id)[0]}
                onChange={handleExerciseChange}
                renderInput={(params) => (
                    <TextField {...params} label={"Search or select an exercise"} variant="outlined" />
                )}
            />

            {/* Test metric */}
            <FormControl fullWidth style={{marginTop: '10px'}}>
                <InputLabel id="metric-type-label">Test Metric</InputLabel>
                <Select
                    labelId="metric-type-label"
                    label="Test Metric"
                    id="metric-type"
                    value={exercise.metric}
                    onChange={e => handleFieldChange('metric', e)}
                >                    
                    <MenuItem value={'Reps'}>Total Reps</MenuItem>
                    <MenuItem value={'Duration'}>Hold</MenuItem> 
                    <MenuItem value={'Speed'}>Speed</MenuItem>
                    <MenuItem value={'Weight'}>Max Weight</MenuItem>
                </Select>
            </FormControl>

            <TextField fullWidth style={{ marginTop: "10px" }}
                    type="text"
                    id="test-details"
                    label="Test Details e.g. max in 30 seconds"
                    variant="outlined"
                    value={exercise.details}
                    onChange={e => handleFieldChange('details', e)}
                />

            {/** Reps */}
            {/* {exercise.metric === 'weight' &&
                <TextField fullWidth style={{marginTop: '15px'}}
                    type="number"
                    id="reps"
                    label="Reps"
                    variant="outlined"
                    value={exercise.reps}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => handleFloatChange('reps', e, 0, 'none')}
                    onChange={(e) => handleFieldChange('reps', e)}
                />
            } */}

            {/* {(exercise.metric !== 'duration' && exercise.metric !== 'speed') &&
                <TextField fullWidth style={{marginTop: '15px'}}
                    type="number"
                    id="duration"
                    label="Duration (s)"
                    variant="outlined"
                    value={exercise.duration}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => handleFloatChange('duration', e, 0, 'none')}
                    onChange={(e) => handleFieldChange('duration', e)}
                />
            } */}

            {/** Weight */}
            {/* {exercise.metric !== 'weight' &&
                <TextField fullWidth style={{marginTop: '15px'}}
                    type="number"
                    id="weight"
                    label="Weight"
                    variant="outlined"
                    value={exercise.weight}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={(e) => handleFloatChange('weight', e, 0, 'none')}
                    onChange={(e) => handleFieldChange('weight', e)}
                />
            }             */}

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    disabled={!exercise.details?.length}
                    onClick={preSubmit}
                    variant="contained"
                >
                    Add
                </Button>

                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>
        </div>
    )
}

export default AddConditioningTestExercise;