import { useState, useEffect } from "react";
import axios from "axios";
import { Fab, Box, Tabs, Tab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import AddDrill from "./components/AddDrill";
import AddTumblingDrill from "./components/AddTumblingDrill.js";
import DrillEntry from "./components/DrillEntry";
import DeleteConfirmationBox from "../../components/DeleteConfirmationBox";
import { useData } from "../../DataContext";
import LoadingMessage from "../../components/LoadingMessage";
import Header from "../../components/general/Header.tsx";
import TabPanel, { a11yProps } from "../../components/general/TabPanel.js";

const Drills = ({ user }) => {

    const userId = user.userId;

    const blankDrill = {
        user_id: userId,
        type: 'trampoline',
        name: '',
        status: 'active',
        drill: {
            drill_id: '1-4-1-1-1-1',
            repetitions: 1,
            length: 4,
            skills: [1,1,1,1]
        },
        difficulty: 0     
    };

    const blankTumbleDrill = {
        user_id: userId,
        type: 'tumbling',
        name: '',
        status: 'active',
        drill: {
            drill_id: '1-3-1-3-5',
            repetitions: 1,
            length: 3,
            skills: [1,3,5]
        },
        difficulty: 0.7
    };

    const [state, setState] = useState('loading');
    const [tabValue, setTabValue] = useState(0);
    const [confirmModal, setConfirmModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);
    const [drillPlaceholder, setDrillPlaceholder] = useState(blankDrill);
    const [drills, setDrills] = useState([]);
    
    
    const { skillsList } = useData();
    const [tumblingSkills, setTumblingSkills] = useState([]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_HOST + `/api/user-drills/${userId}`)
        .then(response => response.json())
        .then(data => {
            if (data !== null) {
                setDrills(data);
            }
            setState('view');
        })
        .catch(error => console.error('Error fetching user drills:', error));

        fetch(process.env.REACT_APP_API_HOST + '/api/skills/tumbling')
        .then(response => response.json())
        .then(skills => {
            setTumblingSkills(skills);
        })
        .catch(error => console.error('Error fetching all tumbling skills:', error));

    }, [userId]); 

    const handleNewDrill = (newDrill) => {

        if (state === 'addDrill' || state === 'addTumbleDrill') {
            axios.post(process.env.REACT_APP_API_HOST + '/api/user-drills', newDrill, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                setDrills([newDrill, ...drills]);
                setState('view');
            })
            .catch(error => {
                console.error('Error posting trampoline entry details:', error);
            }); 
        } else {
            axios.patch(process.env.REACT_APP_API_HOST + '/api/user-drills', newDrill, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                setDrills(drills.map(drill => {
                    if (drill.drill.drill_id === newDrill.drill.drill_id) {
                        return newDrill;
                    }
                    return drill;
                }));
                setState('view');
            })
            .catch(error => {
                console.error('Error patching drill details:', error);
            }); 
        }
    };

    const handleEditing = (drill) => {
        setDrillPlaceholder(drill);

        setState(drill.type === 'trampoline' ? 'addDrill' : 'addTumbleDrill');
    }

    const cancelEntry = () => {
        setState('view');
        setConfirmModal(false);
    };

    const handleConfirmRemove = (id) => {
        setIdToDelete(id);
        setConfirmModal(true);
    }

    const removeEntry = (id) => { 

        axios.delete(process.env.REACT_APP_API_HOST + `/api/user-drills/${userId}/${id}`)
        .then(() => {
            setDrills(drills.filter(item => item.drill.drill_id !== id));
        })
        .catch(error => {
            console.error('Error deleting user drill', error);
        });
        setConfirmModal(false);
        setIdToDelete(0);
    };

    const handleAddPressed = () => {
        setDrillPlaceholder(tabValue === 0 ? blankDrill : blankTumbleDrill);
        setState(tabValue === 0 ? 'addDrill' : 'addTumbleDrill');
    }

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
    };
    
    if (!skillsList?.length || state === 'loading') {
        return <LoadingMessage title={'Drills'} />
    }

    return (
        <>
            <Header title={state === 'view' ? 'Drills' : '  Add Drill  '} />

            <Box sx={{ width: '100%', backgroundColor: 'white', borderRadius: '10px', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Trampoline" sx={{width: '50%', fontSize: '1.4rem'}} {...a11yProps(0)}  />
                    <Tab label="Tumbling" sx={{width: '50%', fontSize: '1.4rem'}} {...a11yProps(1)} />
                </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
                {(state === 'view' && drills.length) &&
                    <div className='entries-in-tabs'>
                        {drills.filter(drill => drill.type === 'trampoline').map((drill, index) => (
                            <DrillEntry key={index}  skillsList={skillsList} drill={drill} index={index} setEditing={handleEditing} remove={handleConfirmRemove} />
                        ))}
                    </div>
                } 
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                {(state === 'view' && drills.length) &&
                    <div className='entries-in-tabs'>
                        {drills.filter(drill => drill.type === 'tumbling').map((drill, index) => (
                            <DrillEntry key={index}  skillsList={tumblingSkills} drill={drill} index={index} setEditing={handleEditing} remove={handleConfirmRemove} />
                        ))}
                    </div>
                } 
            </TabPanel>

            {state === 'addDrill' && 
                <AddDrill type={state} drillHolder={drillPlaceholder} skillsList={skillsList} handleNewDrill={handleNewDrill} handleCancel={cancelEntry} />
            }

            {state === 'addTumbleDrill' && 
                <AddTumblingDrill type={state} drillHolder={drillPlaceholder} skillsList={tumblingSkills} handleNewDrill={handleNewDrill} handleCancel={cancelEntry} />
            }

            { confirmModal === true && 
                <DeleteConfirmationBox message={"Are you sure you want to delete this drill?"} handleConfirm={() => removeEntry(idToDelete)} handleCancel={cancelEntry}/>
            }

            {state === 'view' &&
                <Fab color="secondary" aria-label="add" onClick={handleAddPressed} style={{ position: 'absolute', bottom: '20px', right: '0px' }}>
                    <AddIcon />
                </Fab>
            }
            
        </>
    )
}

export default Drills;