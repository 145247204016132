import { useState } from 'react';
import { IconButton, Button } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit'

const ConditioningEntry = ({ entry, circuit, exerciseList, setEditing, remove }) => {
    const [state, setState] = useState(false);

    const handleDeleteClick = () => {
        remove(entry.id);
    };

    const handleButtonClick = () => {
        setState(!state);
    };

    if (entry.type === 'exercise') {
        const exName = exerciseList.find(ex => (ex.id === entry.exercise_id && ex.type === entry.exercise_type)).name;
        return (
            <div className='entry' style={{position: 'relative', border: '2px solid white', borderRadius: '10px', padding: '5px', margin: '5px 0'}}>
                <IconButton style={{cursor: 'pointer', position: 'absolute', top: '5px', right: '5px', padding: '0'}} aria-label="delete" size="large" alt='delete' onClick={handleDeleteClick}>
                    <DeleteIcon color="black" fontSize="inherit" />
                </IconButton>
                <IconButton style={{cursor: 'pointer', position: 'absolute', top: '5px', right: '30px', padding: '0'}} aria-label="edit" size="large" alt='edit' onClick={() => setEditing(entry)}>
                    <EditIcon fontSize="inherit" />
                </IconButton>

                <h3>{entry.type}: {exName}</h3>
                <p>Reps: {entry.reps}</p>
                {entry.duration > 0 && <p>Duration: {entry.duration}s</p>}
                {entry.weight > 0 && <p>Weight: {entry.weight}kg</p>}
            </div>
        )
    }
    else {
        return (
            <div className='entry' style={{position: 'relative', border: '2px solid white', borderRadius: '10px', padding: '5px', margin: '5px 0'}}>
                <IconButton style={{cursor: 'pointer', position: 'absolute', top: '5px', right: '5px', padding: '0'}} aria-label="delete" size="large" alt='delete' onClick={handleDeleteClick}>
                    <DeleteIcon color="black" fontSize="inherit" />
                </IconButton>
                <IconButton style={{cursor: 'pointer', position: 'absolute', top: '5px', right: '30px', padding: '0'}} aria-label="edit" size="large" alt='edit' onClick={() => setEditing(entry)}>
                    <EditIcon fontSize="inherit" />
                </IconButton>

                <h3>{entry.type}: {circuit.title}</h3>
                <p>Sets: {entry.sets}</p>

                {state && 
                    circuit.exercises.map((cEx, index) => {
                        const exercise = exerciseList.find(ex => (ex.id === cEx.exercise_id && ex.type === cEx.exercise_type));
                        return (
                            <p key={index}>
                                {index + 1}, {exercise ? exercise.name : ""}
                            </p>
                        );
                    })
                }

                <Button style={{cursor: 'pointer', fontSize: '12px', color: '#000'}} onClick={handleButtonClick}>
                    {!state ? 'Show exercises' : 'Hide exercises'}
                </Button>
                    
            </div>
        )
    }
};

export default ConditioningEntry;