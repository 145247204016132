import { Tab, Tabs, Box } from "@mui/material";
import { useState } from "react";
import Header from "../../components/general/Header.tsx";
import TabPanel, { a11yProps } from "../../components/general/TabPanel.js";
import ConditioningSessions from "./components/ConditioningSessions.js";
import ConditioningTestSessions from "./components/testing/ConditioningTestSessions.js";

const Conditioning = ({ user }) => {

    const [tabValue, setTabValue] = useState(0);
    
    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Header title={'Conditioning'} />

            <Box sx={{ width: '100%', backgroundColor: 'white', borderRadius: '10px', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
                    <Tab label="Sessions" sx={{width: '50%', fontSize: '1.4rem'}} {...a11yProps(0)}  />
                    <Tab label="Testing" sx={{width: '50%', fontSize: '1.4rem'}} {...a11yProps(1)} />
                </Tabs>
            </Box>
            
            <TabPanel value={tabValue} index={0}>
                <ConditioningSessions user={user} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <ConditioningTestSessions user={user} />
            </TabPanel>
        </>
    )

};

export default Conditioning;