import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';



export default function DiarySpeedDial({addWater, addEntry}) {
  
  const actions = [
    { icon: <CommentOutlinedIcon color='secondary' onClick={addEntry} />, name: 'Entry' },
    { icon: <WaterDropOutlinedIcon color='secondary' onClick={addWater} />, name: 'Water' },
  ];
  
  return (
    <Box sx={{ height: 160, transform: 'translateZ(0px)', flexGrow: 1, position: 'absolute', bottom: '50px', right: '0' }}>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
          />
        ))}
      </SpeedDial>
    </Box>
  );
}