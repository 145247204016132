import { useState } from "react";
import { ToggleButton, ToggleButtonGroup, TextField, FormControl, InputLabel, Select, MenuItem, Button, Autocomplete } from "@mui/material";
import filterSkillsList from "../../../components/utils/FilterSkillsList";


const AddDrill = ({type, drillHolder, skillsList, handleNewDrill, handleCancel}) => {

    const [drill, setDrill] = useState(drillHolder);

    const handleDrillChange = (e, field, index) => {
        
        let newDrill = drill.drill

        if (field === 'length') {
            let newLen = e.target.value;
            let newSkills = drill.drill.skills.slice(0, newLen);
            for (let i = newSkills.length; i < newLen; i++) {
                newSkills[i] = 1;
            }
            newDrill = {
                ...newDrill,
                length: newLen,
                skills: newSkills
            }
            setDrill({
                ...drill,
                drill: newDrill
            });
        }
        else if (field === 'skills') {
            let newSkills = drill.drill.skills;

            const selectedSkill = e.target.innerText;

            if (selectedSkill !== undefined && selectedSkill.length !== 0) {
                newSkills[index] = skillsList.find(value => 
                    (value.name + ' (' + value.fig + value.shape + ')') === selectedSkill
                ).id;
                
                newDrill = {
                    ...newDrill,
                    skills: newSkills
                }
                
                setDrill({
                    ...drill,
                    drill: newDrill
                });
            }
        }
        else if (field === 'repetitions') {
            newDrill = {
                ...newDrill,
                repetitions: e.target.value
            }
            setDrill({
                ...drill,
                drill: newDrill
            });
        }
        else {
            setDrill({
                ...drill,
                [field]: e.target.value
            });
            
        }
    };

    const handleSubmit = () => {
        let newDrill = drill.drill;
        let drill_id = drill.drill.length + '-' + drill.drill.repetitions;
        
        const difficulty = drill.drill.repetitions * parseFloat(drill.drill.skills.reduce((total, current) => total + skillsList.filter((value) => value.id === current)[0].difficulty, 0).toFixed(1));

        drill.drill.skills.forEach(skill => {
            drill_id += '-' + skill
        });

        newDrill = {
            ...newDrill,
            drill_id: drill_id
        };

        handleNewDrill({
            ...drill, 
            drill: newDrill,
            difficulty: difficulty
        });
    };

    return (
        <form className="new-entry" style={{backgroundColor: '#fff', padding: '5px', borderRadius: '10px'}}>
            
            {/* Name or Title of drill */}    
            <TextField fullWidth style={{ marginTop: "10px" }}
                type="text"
                id="drill-name"
                label="Drill Name"
                variant="outlined"
                value={drill.name}
                onChange={(e) => handleDrillChange(e, 'name', 1)}
            />

            {/* Set status */}
            {type === 'editing' && <>
                <ToggleButtonGroup
                    fullWidth
                    style={{margin: '10px 0 5px'}}
                    color="primary"
                    value={drill.status}
                    exclusive
                    onChange={(e) => handleDrillChange(e, 'status', 1)}
                    aria-label="Active status"
                >
                    <ToggleButton color="success" value={'active'} aria-label="active">Active</ToggleButton>
                    <ToggleButton color="error" value={'inactive'} aria-label="inactive">Inactive</ToggleButton>
                </ToggleButtonGroup>
            </>}

            {/* Number of skills */}
            <FormControl style={{marginTop: '10px', width: '49%',marginRight: '2%'}}>
                <InputLabel id="number-skills-label">Number of skills</InputLabel>
                <Select
                    disabled={type === 'editing'}
                    labelId="number-skills-label"
                    id="number-skills"
                    value={drill.drill.length}
                    label="Number of skills"
                    onChange={(e) => handleDrillChange(e, 'length', 1)}
                >
                    {[...Array(10).keys()].map((i) => (
                        <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Number of repetitions */}
            <FormControl style={{marginTop: '10px', width: '49%'}}>
                <InputLabel id="number-repetitions-label">Number of repetitions</InputLabel>
                <Select
                    disabled={type === 'editing'}
                    labelId="number-repetitions-label"
                    id="number-repetitions"
                    value={drill.drill.repetitions}
                    label="Number of reps"
                    onChange={(e) => handleDrillChange(e, 'repetitions', 1)}
                >
                    {[...Array(5).keys()].map((i) => (
                        <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Skill dropdowns */} 
            {[...Array(drill.drill.length)].map((_, index) => (
                <Autocomplete
                    key={index}
                    disabled={type === 'editing' ? true : false}
                    style={{ marginTop: '10px' }}
                    options={filterSkillsList(skillsList, drill.drill.skills, index, drill.drill.length)}
                    getOptionLabel={(option) => (option.name + ' (' + option.fig + option.shape + ')')}
                    isOptionEqualToValue={() => true}
                    value={skillsList.filter((skill) => skill.id === drill.drill.skills[index])[0]}
                    onChange={(e) => handleDrillChange(e, 'skills', index)}
                    renderInput={(params) => (
                        <TextField {...params} label={`Skill ${index + 1}`} variant="outlined" />
                    )}
                />
            ))}

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    disabled={drill.name === ''}
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Add drill
                </Button>

                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>
        </form>
    )

}

export default AddDrill;