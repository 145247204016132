import { Fab } from "@mui/material";
import { useState, useEffect } from "react";
import AddIcon from '@mui/icons-material/Add';
import SessionEntry from "./components/SessionEntry.js";
import CreateTrainingSession from "./components/CreateTrainingSession.js";
import Header from "../../components/general/Header.tsx";
import LoadingMessage from "../../components/LoadingMessage";


const DiarySelection = ({ existsActiveSession, handleActiveSessionChange, user }) => {

    const userId = user.userId;

    const [state, setState] = useState('loading');
    const [sessions, setSessions] = useState([]);

   
    useEffect(() => {
        // Fetch data when the component mounts
        fetch(process.env.REACT_APP_API_HOST + `/api/sessions/${userId}`)
        .then(response => response.json())
        .then(sessions => {
            setSessions(sessions);
            setState('select');
        })
        .catch(error => console.error('Error fetching sessions:', error));

    }, [userId]);


    if (state === 'loading') {
        return <LoadingMessage title={'Sessions'} />
    }

    return (
        <>
            {state === 'select' && 
                <Header title={'Sessions'} />
            }

            {/* {!existsActive && <Box sx={{textAlign: 'center', position: 'fixed', top: '60px', left: '50%', transform: 'translateX(-50%)', height: '33px', width: 'calc(100% - 40px)'}}>
                <Button style={{fontSize: '12px', color: '#000'}} onClick={() => {setExistsActive(true); setState('create')}}>(+) Start new session</Button>
            </Box>} */}
            
            {state === 'create' && 
                <CreateTrainingSession userId={userId} defaultSlide={3} handleActiveSessionChange={handleActiveSessionChange} />
            }
             
            {(state === 'select' && sessions.length !== 0) &&
                <div className='entries'>
                    {sessions.map((session) => (
                        <SessionEntry key={session.id} session={session} />
                    ))} 
                </div>
            }   
            {(state === 'select') &&
                <Fab color="secondary" aria-label="add" onClick={() => { setState('create')}} style={{ position: 'absolute', bottom: '20px', right: '0px' }}>
                    <AddIcon />
                </Fab>
            }    
        </>
    )
};

export default DiarySelection;