import { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import { IconButton, Box } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ConditioningSpeedDial from './ConditioningSpeedDial';
import LoadingMessage from '../../../components/LoadingMessage';
import DeleteConfirmationBox from '../../../components/DeleteConfirmationBox';
import ConditioningEntry from './ConditioningEntry.js';
import AddConditioningCircuit from './AddConditioningCircuit';
import Header from '../../../components/general/Header.tsx';
import CreateConditioningCircuit from './CreateConditioningCircuit.js';
import AddConditioningExercise from './AddConditioningExercise.js';
import CreateCustomExercise from './CreateCustomExercise.js';

const ConditioningSession = ({ user }) => {

    const userId = user.userId;
    const location = useLocation();
    const session = location.state.session;

    const [userCircuits, setUserCircuits] = useState([]);
    const [entries, setEntries] = useState([]);
    const [exerciseList, setExerciseList] = useState([]);
    const [state, setState] = useState('loading');

    const [confirmModal, setConfirmModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);

    const emptyCircuit = {
        user_id: userId,
        session_id: session.id,
        title: "",
        sets: 1
    }
    const [circuitPlaceholder, setCircuitPlaceholder] = useState(emptyCircuit);
    

    useEffect(() => {
        fetch(process.env.REACT_APP_API_HOST + `/api/conditioning/sessions/entry/${userId}/${session.id}`)
        .then(response => response.json())
        .then(data => {
            if (data !== null) { 
                setEntries(data);
            }
        })
        .catch(error => console.error('Error fetching entries:', error));

        fetch(process.env.REACT_APP_API_HOST + `/api/conditioning/user-circuits/${userId}`)
        .then(response => response.json())
        .then(data => {
            if (data !== null) { 
                setUserCircuits(data);
            }
        })
        .catch(error => console.error('Error fetching user circuits:', error));

        fetch(process.env.REACT_APP_API_HOST + `/api/conditioning/exercises/${userId}`)
        .then(response => response.json())
        .then(items => setExerciseList(items))
        .catch(error => console.error('Error fetching exercises:', error));

        setState('view');

    }, [session, userId]);

    const handleAddCircuit = (newCircuit) => {
        if (state === 'addCircuit') {
            axios.post(process.env.REACT_APP_API_HOST + '/api/conditioning/sessions/circuits', newCircuit, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                // Extract session ID from the response
                const { last_id } = response.data;
                let newEntryWithId = {...newCircuit, id: last_id};
                setEntries([newEntryWithId, ...entries]);
                setState('view');
            })
            .catch(error => {
                console.error('Error posting new conditioning circuit:', error);
            });  
        } else {
            const newCircuitWithIDs = {
                ...newCircuit,
                user_id: userId,
                session_id: session.id
            }
            axios.put(process.env.REACT_APP_API_HOST + `/api/conditioning/sessions/circuits`, newCircuitWithIDs, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                setEntries(entries.map(entry => {
                    if (entry.id === newCircuit.id) {
                        return newCircuit
                    }
                    return entry
                }));
                setState('view');
            })
            .catch(error => {
                console.error(`Error updating circuit details:`, error);
            }); 
        }
    }

    const handlAddExercise = (newExercise) => {
        const newExerciseWithIDs = {
            ...newExercise,
            user_id: userId,
            session_id: session.id
        };
        if (state === 'addExercise') {
            axios.post(process.env.REACT_APP_API_HOST + '/api/conditioning/sessions/exercise', newExerciseWithIDs, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                // Extract session ID from the response
                const { last_id } = response.data;
                let newEntryWithId = {...newExerciseWithIDs, id: last_id};
                setEntries([newEntryWithId, ...entries]);
                setState('view');
            })
            .catch(error => {
                console.error('Error posting conditioning session exercise:', error);
            });  
        } else {
            axios.put(process.env.REACT_APP_API_HOST + `/api/conditioning/sessions/exercise`, newExerciseWithIDs, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                setEntries(entries.map(entry => {
                    if (entry.id === newExercise.id) {
                        return newExercise
                    }
                    return entry
                }));
                setState('view');
            })
            .catch(error => {
                console.error(`Error updating exercise entry details:`, error);
            }); 
        }
    }

    const handleConfirmRemove = (id) => {
        setIdToDelete(id);
        setConfirmModal(true);
    }

    const handleDelete = (circuit_id) => {
        axios.delete(process.env.REACT_APP_API_HOST + `/api/conditioning/sessions/entry/${userId}/${session.id}/${circuit_id}`)
        .then(() => {
            setEntries(entries.filter(item => item.id !== circuit_id));
            setConfirmModal(false);
            setIdToDelete(0);
        })
        .catch(error => {
            console.error('Error deleting conditioning circuit', error);
        });
    }

    const handleNewUserCircuit = (newCircuit) => {
        axios.post(process.env.REACT_APP_API_HOST + `/api/conditioning/user-circuits`, newCircuit)
        .then(response => {
            // Extract session ID from the response
            const { last_id } = response.data;
            const newCircuitWithId = {...newCircuit, id: last_id};
            setUserCircuits([newCircuitWithId, ...userCircuits]);
            setState('view');
        })
        
    };

    const handleNewCustomExercise = (exName) => {
        const newExercise = {
            user_id: userId,
            name: exName
        };
        axios.post(process.env.REACT_APP_API_HOST + `/api/conditioning/exercises`, newExercise)
        .then(response => {
            // Extract session ID from the response
            const { last_id } = response.data;
            setExerciseList([{name: exName, id: last_id, type: 'custom'}, ...exerciseList]);
            setState('view');
        })
    }

    const handleEditing = (entry) => {
        setCircuitPlaceholder(entry);
        setState('editCircuit');
    };

    const cancelEntry = () => {
        setState('view');
        setConfirmModal(false);
        setIdToDelete(0);
    };

    const addCircuit = () => {
        setCircuitPlaceholder(emptyCircuit);
        setState('addCircuit');
    };

    const addExercise = () => {
        setCircuitPlaceholder(emptyCircuit);
        setState('addExercise');
    };

    const createExercise = () => {
        setState('createExercise')
    };

    const createCircuit = () => {
        setState('createCircuit')
    };

    // if (state === 'error') {
    //     return <LoadingMessage title={'Error'} />
    // }

    if (state === 'loading') {
        return <LoadingMessage title={'Conditioning'} />
    }

    return (
        <>
            {state === 'view' && <>

                <Box sx={{ fontSize: '40px', textAlign: 'center', height: '60px', position: 'fixed', top: '18px', left: '50%', transform: 'translateX(-50%)', width: 'calc(100% - 40px)' }}>
                    <Link to="/conditioning">
                        <IconButton style={{position: 'absolute', top: '11px', left: '0', padding: '0', fontSize: "40px", color: "#000"}} aria-label="back" size="large" alt='back' >
                            <ArrowBackIcon color="black" fontSize="inherit" />
                        </IconButton>
                    </Link>
                </Box>
                <Header title={session.title} />
            
                <div className='entries'>
                    {entries.map((entry) => (
                        <ConditioningEntry key={entry.id} entry={entry} circuit={userCircuits.find(c => c.id === entry.circuit_id) ?? {}} exerciseList={exerciseList} setEditing={handleEditing} remove={handleConfirmRemove} />
                    ))} 
                </div>
            </>}

            {(state === 'addCircuit' || state === 'editCircuit') && 
                <AddConditioningCircuit circuitHolder={circuitPlaceholder} userCircuits={userCircuits} handleSubmit={handleAddCircuit} handleCancel={cancelEntry} />
            }

            {(state === 'addExercise' || state === 'editExercise') &&
                <AddConditioningExercise exerciseList={exerciseList} handleSubmit={handlAddExercise} handleCancel={cancelEntry} />
            }

            {state === 'createCircuit' && 
                <CreateConditioningCircuit userId={userId} submitHandler={handleNewUserCircuit} handleCancel={cancelEntry} />
            }

            {state === 'createExercise' && 
                <CreateCustomExercise handleSubmit={handleNewCustomExercise} handleCancel={cancelEntry} />
            }
            
            {(state === 'view') &&
                <ConditioningSpeedDial 
                    iconOneAction={addCircuit} 
                    iconOneText={'Add Set'}
                    iconTwoAction={addExercise} 
                    iconTwoText={'Add Exercise'}
                    iconThreeAction={createExercise} 
                    iconThreeText={'Create Exercise'}
                    iconFourAction={createCircuit} 
                    iconFourText={'Create Circuit'}
                />
            }    

            {confirmModal && 
                <DeleteConfirmationBox message={"Are you sure you want to delete this circuit?"} handleConfirm={() => handleDelete(idToDelete)} handleCancel={cancelEntry}/>
            }
        </>
    )
}

export default ConditioningSession;