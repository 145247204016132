
import { useState } from 'react';
import { TextField, MenuItem } from '@mui/material';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import '../../../style/Moodscreen.css'
import RoundDownToFiveMinutes from '../../../components/utils/RoundTimeToFiveMinutes';

const SessionDetails = ({/*dateChange, disciplineChange, timeChange,*/ handleSubmit, handleClose}) => {

  const [sessionDate, setSessionDate] = useState(new Date());
  const [startTime, setStartTime] = useState(dayjs(RoundDownToFiveMinutes(new Date())));
  const [selectedDiscipline, setSelectedDiscipline] = useState('trampoline');
  const [sessionAims, setSessionAims] = useState('');

  // const handleDateChange = (date) => {
  //   setSessionDate(date);
  //   dateChange(date);
  // };

  // const handleDisciplineChange = (discipline) => {
  //   setSelectedDiscipline(discipline);
  //   disciplineChange(discipline);
  // };

  // const handleTimeChange = (newTime) => {
  //   setStartTime(newTime);
  //   timeChange(newTime);
  // };

  const preSubmit = () => {
    const sessionFields = {
      discipline: selectedDiscipline,
      sessionDate: sessionDate,
      startTime: startTime,
      aims: sessionAims 
    };

    console.log(sessionFields);

    handleSubmit(sessionFields);
  };

  const inputStyle = {
    fontSize: '16px',      // Adjust the font size for inputs as needed
    textAlign: 'center',   // Center-align the text inside the input
  };
    
  const labelStyle = {
    width: '100%',         // Ensure the label text spans the entire width
    // textAlign: 'center',   // Center-align the label
    // transformOrigin: 'center', // Center the transform origin for the label
  };

  const menuItemStyle = {
    fontSize: '14px',
  };

  return (<>
    <div>
      <img id="close-modal" src="../img/icon/reject.png" alt='close' onClick={() => handleClose()} />
    </div>
    <div className="confirm-modal">
      
      <h2 style={{marginBottom: '10px'}}>Session Details</h2>


      <TextField fullWidth sx={{margin: '1rem 0'}}
        id="discipline"
        label="Discipline *"
        select
        value={selectedDiscipline}
        onChange={(e) => setSelectedDiscipline(e.target.value)}
        InputLabelProps={{ shrink: true }}
      >
        <MenuItem value="trampoline" style={menuItemStyle}>Trampoline</MenuItem>
        <MenuItem value="dmt" style={menuItemStyle}>DMT</MenuItem>
        <MenuItem value="tumbling" style={menuItemStyle}>Tumbling</MenuItem>
      </TextField>

      <TextField fullWidth style={{ marginTop: '1rem' }} 
        type="date"
        id="session-date"
        label="Session Date"
        value={sessionDate.toISOString().split('T')[0]}
        onChange={(e) => setSessionDate(new Date(e.target.value))}
        InputLabelProps={{
          shrink: true,
          sx: labelStyle,
        }}
        inputProps={{
          style: inputStyle,
        }}
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopTimePicker 
          label="Start time" 
          value={startTime}
          onChange={(time) => {setStartTime(time)}}
          slotProps={{
            textField: {
              fullWidth: true,
              InputProps: {
                sx: {
                  fontSize: '16px', // Increase input text font size
                  textAlign: 'center',
                  '& input': {
                    textAlign: 'center', // center text inside input field
                  },
                },
              },
              InputLabelProps: {
                shrink: true,
                sx: {
                  fontSize: '12px', // Increase the label font size
                },
              },
              sx: {
                marginTop: '15px',
                },
              },
            }}
        />
      </LocalizationProvider>

      <TextField fullWidth style={{ marginTop: "15px" }}
        type="text"
        multiline
        rows={4}
        id="session-aim"
        label="Session Aims"
        variant="outlined"
        value={sessionAims}
        onChange={e => setSessionAims(e.target.value)}
      />

      <div className="start-session">
        <input id="begin-training" type="submit" value={'Begin training!'} onClick={preSubmit} />
      </div>
    </div>
  </>
    
  );
};

export default SessionDetails;
