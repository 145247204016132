import { useNavigate } from 'react-router-dom';
import '../../../style/Moodscreen.css'

const MoodConf = ({moodVal, handleClose, handleConfirm}) => {

    const allowBegin = moodVal >= 2 ? true : false;
    const navigate = useNavigate();

    const close = () => {
        handleClose();
    };

    let source;
    let modalText;

    switch (moodVal) {
        case 0:
            source = "../img/mood_s/very_bad.png";
            modalText = "Before you start training, it's important to speak to your coach to make a plan to manage your mood.";
            break;
        case 1:
            source = "../img/mood_s/bad.png";
            modalText = "Before you start training, it's important to speak to your coach to make a plan to manage your mood.";
            break;
        case 2:
            source = "../img/mood_s/neutral.png";
            modalText = "Let your coach know you're feeling a bit meh. Maybe a great training session will make you smile!";
            break;
        case 3:
            source = "../img/mood_s/good.png";
            modalText = "It's great you're feeling happy and ready to go. Have a great session!";
            break;
        case 4:
            source = "../img/mood_s/very_good.png";
            modalText = "It's great you're feeling happy and ready to go. Have a great session!";
            break;
        default:
            return;
    }

    return (<>
        <div>
            <img id="close-modal" src="../img/icon/reject.png" alt='close' onClick={close} />
        </div>
        <div className="confirm-modal">
            <div className="mood-image">
                <img src={source} id="selected-image" alt='mood' />
            </div>
            <p id="modalContent">{modalText}</p>
            { allowBegin ? <>
                <div className="start-session">
                    <input id="begin-training" type="submit" value={'Good to go!'} onClick={handleConfirm} />
                </div>
            </> : <>
                <div className="start-session">
                    <input id="begin-training" type="submit" value={'Good to go!'} onClick={handleConfirm} />
                </div>
                <div className="start-session">
                    <input id="skip-training" type="submit" value={'Miss this one out'} onClick={() => navigate('/')} />
                </div>
            </>}  
        </div>
    </>)
};

export default MoodConf;