import React, { createContext, useContext, useState, useEffect } from 'react';


const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [skillsList, setSkillsList] = useState(null);

  useEffect(() => {
    // Fetch data when the component mounts    
    fetch(process.env.REACT_APP_API_HOST + '/api/skills/trampoline')
    .then(response => response.json())
    .then(skillsList => {
        setSkillsList(skillsList);
    })
    .catch(error => console.error('Error fetching all trampoline skills:', error))
}, []); 

  return (
    <DataContext.Provider value={{ skillsList }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};
