import { Button } from "@mui/material";
import { useState } from "react";

const NewSkillFocus = ({newSkill, handleUpdate, handleExit}) => {

    const [skillProgress, setSkillProgress] = useState(newSkill);

    const bgColor = () => {
        if (newSkill.proficiency === 0) {
            return "#D2222D";
        }
        else if (newSkill.proficiency === 1) {
            return "#FFBF00";
        }
        else {
            return "#238823";
        }
    }  

    return (
        <div style={{position: 'fixed', top: '0', left: '0', right: 0, height: 'calc(100% - 56px)', backgroundColor: 'rgba(160, 160, 160, 0.8)'}}>
            
            {/* <img id="close-modal" src="../img/icon/reject.png" alt='close' style={{right: '20px', top: '20px', border: '3px solid black', borderRadius: '100%'}} onClick={handleExit} /> */}

            <div style={{ width: 'calc(100% - 40px)', position: 'absolute', top: '5%', bottom: '0', margin:'auto 20px', height: 'fit-content'}}>
                <div style={{border: '3px solid black', borderRadius: '15px', backgroundColor: bgColor()}}>
                    <h2>{newSkill.name}</h2>
                    <div className="hydration-buttons" style={{margin: '10px 10% 2%'}}>  
                        <p style={{fontWeight: '1000'}}>In rig:</p>                  
                        <Button variant="outlined" size="medium" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}} onClick={() => setSkillProgress({...skillProgress, in_rig: skillProgress.in_rig === 0 ? 0 : skillProgress.in_rig - 1})}>-</Button>
                        <p style={{fontWeight: '1000'}}>{skillProgress.in_rig}</p>
                        <Button variant="outlined" size="large" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}}  onClick={() => setSkillProgress({...skillProgress, in_rig: skillProgress.in_rig + 1})}>+</Button>
                    </div>
                    <div className="hydration-buttons" style={{margin: '0px 10% 2%'}}>   
                        <p style={{fontWeight: '1000'}}>To pit:</p>                   
                        <Button variant="outlined" size="medium" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}} onClick={() => setSkillProgress({...skillProgress, to_pit: skillProgress.to_pit === 0 ? 0 : skillProgress.to_pit - 1})}>-</Button>
                        <p style={{fontWeight: '1000'}}>{skillProgress.to_pit}</p>
                        <Button variant="outlined" size="large" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}}  onClick={() => setSkillProgress({...skillProgress, to_pit: skillProgress.to_pit + 1})}>+</Button>
                    </div>
                    <div className="hydration-buttons" style={{margin: '0px 10% 2%'}}>   
                        <p style={{fontWeight: '1000'}}>Caught:</p>                   
                        <Button variant="outlined" size="medium" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}} onClick={() => setSkillProgress({...skillProgress, mat_with_catch: skillProgress.mat_with_catch === 0 ? 0 : skillProgress.mat_with_catch - 1})}>-</Button>
                        <p style={{fontWeight: '1000'}}>{skillProgress.mat_with_catch}</p>
                        <Button variant="outlined" size="large" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}}  onClick={() => setSkillProgress({...skillProgress, mat_with_catch: skillProgress.mat_with_catch + 1})}>+</Button>
                    </div>
                    <div className="hydration-buttons" style={{margin: '0px 10% 2%'}}>   
                        <p style={{fontWeight: '1000'}}>To mat:</p>                   
                        <Button variant="outlined" size="medium" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}} onClick={() => setSkillProgress({...skillProgress, to_mat: skillProgress.to_mat === 0 ? 0 : skillProgress.to_mat - 1})}>-</Button>
                        <p style={{fontWeight: '1000'}}>{skillProgress.to_mat}</p>
                        <Button variant="outlined" size="large" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}}  onClick={() => setSkillProgress({...skillProgress, to_mat: skillProgress.to_mat + 1})}>+</Button>
                    </div>
                    <div className="hydration-buttons" style={{margin: '0px 10% 2%'}}>   
                        <p style={{fontWeight: '1000'}}>To bed:</p>                   
                        <Button variant="outlined" size="medium" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}} onClick={() => setSkillProgress({...skillProgress, to_bed: skillProgress.to_bed === 0 ? 0 : skillProgress.to_bed - 1})}>-</Button>
                        <p style={{fontWeight: '1000'}}>{skillProgress.to_bed}</p>
                        <Button variant="outlined" size="large" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}}  onClick={() => setSkillProgress({...skillProgress, to_bed: skillProgress.to_bed + 1})}>+</Button>
                    </div>
                    <div className="hydration-buttons" style={{margin: '0px 10% 2%'}}>   
                        <p style={{fontWeight: '1000'}}>In combo:</p>                   
                        <Button variant="outlined" size="medium" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}} onClick={() => setSkillProgress({...skillProgress, in_combination: skillProgress.in_combination === 0 ? 0 : skillProgress.in_combination - 1})}>-</Button>
                        <p style={{fontWeight: '1000'}}>{skillProgress.in_combination}</p>
                        <Button variant="outlined" size="large" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}}  onClick={() => setSkillProgress({...skillProgress, in_combination: skillProgress.in_combination + 1})}>+</Button>
                    </div>
                    <div className="hydration-buttons" style={{margin: '0px 10% 2%'}}>   
                        <p style={{fontWeight: '1000'}}>In routine:</p>                   
                        <Button variant="outlined" size="medium" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}} onClick={() => setSkillProgress({...skillProgress, in_routine: skillProgress.in_routine === 0 ? 0 : skillProgress.in_routine - 1})}>-</Button>
                        <p style={{fontWeight: '1000'}}>{skillProgress.in_routine}</p>
                        <Button variant="outlined" size="large" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}}  onClick={() => setSkillProgress({...skillProgress, in_routine: skillProgress.in_routine + 1})}>+</Button>
                    </div>
                    <div className="hydration-buttons" style={{margin: '0px 10% 2%'}}>   
                        <p style={{fontWeight: '1000'}}>Competed:</p>                   
                        <Button variant="outlined" size="medium" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}} onClick={() => setSkillProgress({...skillProgress, competed: skillProgress.competed === 0 ? 0 : skillProgress.competed - 1})}>-</Button>
                        <p style={{fontWeight: '1000'}}>{skillProgress.competed}</p>
                        <Button variant="outlined" size="large" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}}  onClick={() => setSkillProgress({...skillProgress, competed: skillProgress.competed + 1})}>+</Button>
                    </div>
                </div>
                <div className="bottom-buttons" style={{ /*display: 'flex', justifyContent: 'center', marginTop: '5%'*/ }}>
                    <div className="skill-bottom-button" style={{ backgroundColor: '#fff', width: '50%' }}>
                        <input id="log-progress" type="submit" value={'Log progress'} onClick={() => handleUpdate(skillProgress)} />
                    </div>
                    <div className="skill-bottom-button" style={{ backgroundColor: '#fff', width: '50%' }}>
                        <input id="cancel" type="button" value={'Cancel'} onClick={handleExit} />
                    </div>

                </div>

            </div>
            
        </div>
    );
};

export default NewSkillFocus;