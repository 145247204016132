import { useNavigate } from 'react-router-dom';
import { IconButton } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';

const ConditioningSessionEntry = ({session, remove}) => {

    const navigate = useNavigate();

    const handleParentClick = () => {
        navigate("/conditioning/session", { state: { session }});
    };

    const handleDeleteClick = (event) => {
        event.stopPropagation();
        remove(session.id);
    };


    return (
        <div className='entry' style={{cursor: 'pointer', position: 'relative', border: '2px solid white', borderRadius: '10px', padding: '5px', margin: '5px 0'}} onClick={handleParentClick}>
            
            <IconButton style={{position: 'absolute', top: '5px', right: '5px', padding: '0'}} aria-label="delete" size="large" alt='delete' onClick={handleDeleteClick}>
                <DeleteIcon color="black" fontSize="inherit" />
            </IconButton>

                <h3>{session.title}:</h3>
                <p>Session date: {new Date(session.start_time).toLocaleDateString('en-GB')}</p>
                <p>Start time: {new Date(session.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                {session.end_time !== null && 
                    <p>End time: {new Date(session.end_time).toLocaleDateString('en-GB')}</p>
                }
                
        </div>
    )
};

export default ConditioningSessionEntry;