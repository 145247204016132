import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../../style/Carousel.css';

const Carousel = ({ images, defaultSlide, onRecord }) => {
  const [currentSlide, setCurrentSlide] = useState(defaultSlide);

  const handleRecord = (index) => {
    onRecord(index); // Pass index back to mood screen
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Number of images to display
    slidesToScroll: 1,
    initialSlide: defaultSlide,
    centerMode: true,
    centerPadding: '50px',
    afterChange: (index) => setCurrentSlide(index)
  };

  return (
    <>
      <h2>How are you feeling today?</h2>
      <div style={{ borderRadius: '15px', position: 'absolute', top: '0', bottom: '0', margin:'auto 0', height: 'fit-content', width: '100%', padding: '0 5px' }}>
        <Slider {...settings}>
            {images.map((image, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={image} alt={`Slide ${index + 1}`} />
                </div>
            ))}
        </Slider>
        <div className={'record-mood-container'}>
          <input type="button" value="Record mood" id="record-mood" onClick={() => {handleRecord(currentSlide)}} />  
        </div>
      </div>
    </>
  );
};

export default Carousel;