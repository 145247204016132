import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";

const PlaySkillFocus = ({playSkill, handleUpdate, handleExit}) => {

    const [attempts, setAttempts] = useState(playSkill.attempts);
    const [proficiency, setProficiency] = useState(playSkill.proficiency);

    const bgColor = () => {
        if (playSkill.proficiency === 0) {
            return "#D2222D";
        }
        else if (playSkill.proficiency === 1) {
            return "#FFBF00";
        }
        else {
            return "#238823";
        }
    }  

    return (
        <div style={{position: 'fixed', top: '0', left: '0', right: 0, height: 'calc(100% - 56px)', backgroundColor: 'rgba(160, 160, 160, 0.8)'}}>
            
            {/* <img id="close-modal" src="../img/icon/reject.png" alt='close' style={{right: '20px', top: '40px', border: '3px solid black', borderRadius: '100%'}} onClick={handleExit} /> */}

            <div style={{ width: 'calc(100% - 40px)', position: 'absolute', top: '0', bottom: '0', margin:'auto 20px', height: 'fit-content'}}>
                <div style={{border: '3px solid black', borderRadius: '15px', backgroundColor: bgColor()}}>
                    <h2>{playSkill.name}</h2>
                    <p>Attempts:</p>
                    <div className="hydration-buttons" style={{margin: '10px 20% 10%'}}>                    
                        <Button variant="outlined" size="medium" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}} onClick={() => setAttempts(attempts === 0 ? 0 : attempts - 1)}>-</Button>
                            <p style={{fontWeight: '1000'}}>{attempts}</p>
                        <Button variant="outlined" size="large" style={{backgroundColor: '#fff', fontSize: '16px', border: '1px solid black'}}  onClick={() => setAttempts(attempts + 1)}>+</Button>
                    </div>
                    <ToggleButtonGroup fullWidth
                        style={{padding: '0 15px', marginBottom: '15px'}}
                        value={proficiency}
                        exclusive
                        onChange={(e) => setProficiency(parseInt(e.target.value))}
                        aria-label="Proficiency"
                    >
                        <ToggleButton style={{backgroundColor: '#fff', border: '1px solid black', fontSize: '10px'}} color="error" value={0} aria-label="meh">Meh</ToggleButton>
                        <ToggleButton style={{backgroundColor: '#fff', border: '1px solid black', fontSize: '10px'}} color="warning" value={1} aria-label="getting there">Getting there</ToggleButton>
                        <ToggleButton style={{backgroundColor: '#fff', border: '1px solid black', fontSize: '10px'}} color="primary" value={2} aria-label="got it">Got it!</ToggleButton>                
                    </ToggleButtonGroup>      
                </div>
                {/* <div className="start-session" style={{backgroundColor: '#fff', marginTop: '10%'}}>
                    <input id="log-progress" type="submit" value={'Log progress'} onClick={() => handleUpdate(attempts, proficiency)} />
                </div> */}
                <div className="bottom-buttons" style={{ /*display: 'flex', justifyContent: 'center', marginTop: '5%'*/ }}>
                    <div className="skill-bottom-button" style={{ backgroundColor: '#fff', width: '50%' }}>
                        <input id="log-progress" type="submit" value={'Log progress'} onClick={() => handleUpdate(attempts, proficiency)} />
                    </div>
                    <div className="skill-bottom-button" style={{ backgroundColor: '#fff', width: '50%' }}>
                        <input id="cancel" type="button" value={'Cancel'} onClick={handleExit} />
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default PlaySkillFocus;