import { useState } from "react";
import { TextField, Button } from "@mui/material";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import '../../../style/Carousel.css';
import RoundDownToFiveMinutes from "../../../components/utils/RoundTimeToFiveMinutes";

const CompleteSession = ({startTime, handleCompleteSession, handleCancel}) => {

    const defaultSlide = 3;

    const images = [
        '../img/mood_s/very_bad.png',
        '../img/mood_s/bad.png',
        '../img/mood_s/neutral.png',
        '../img/mood_s/good.png',
        '../img/mood_s/very_good.png',
    ];

    const [session, setSession] = useState(
        {
            end_mood: defaultSlide,
            end_time: dayjs(RoundDownToFiveMinutes(new Date())).isAfter(dayjs(startTime)) ? dayjs(RoundDownToFiveMinutes(new Date())) : dayjs(startTime),
            session_feedback: '',
            session_reflection: ''
        }
    );
    const currentTime = dayjs(RoundDownToFiveMinutes(new Date()));
    const [endTime, setEndTime] = useState(currentTime.isAfter(dayjs(startTime)) ? currentTime : dayjs(startTime));

    const handleFieldChange = (fieldName, e) => {
        if (e.target.value === '') {
            setSession((session) => ({ ...session, [fieldName]: null }));
        } else {
            setSession((session) => ({ ...session, [fieldName]: e.target.value }));
        }
    };

    const handleSubmit = () => {
        // Ensure max session duration is less than a day. If end time is after start time, assume same day. If end time before start time, use next day.
        const parsedStartTime = dayjs(startTime);
        const end_date = (endTime.hour() > parsedStartTime.hour() || (endTime.hour() === parsedStartTime.hour() && endTime.minute() > parsedStartTime.minute()))
            ? parsedStartTime : parsedStartTime.add(1, 'day');

        setSession((prev) => {
            const newSession = {
                ...session,
                end_time: end_date.hour(endTime.hour()).minute(endTime.minute()).second(0).millisecond(0).format('YYYY-MM-DDTHH:mm:ssZ')
            }

            console.log(newSession);
            handleCompleteSession(newSession);
            return newSession;
        })
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1, // Number of images to display
        slidesToScroll: 1,
        initialSlide: session.end_mood,
        centerMode: true,
        centerPadding: '50px',
        afterChange: (index) => setSession({...session, end_mood: index})
    };

    return (
        <div style={{backgroundColor: '#fff', borderRadius: '15px', position: 'absolute', top: '0', bottom: '0', margin:'auto 0', height: 'fit-content', width: '100%', padding: '0 5px'}}>
            <h2 style={{marginBottom: '15px'}}>Finish session</h2>
            
            {/* End mood carousel */}
            <p style={{textAlign: 'start', marginLeft: '10px'}}>How are you feeling?</p>
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={image} alt={`Slide ${index + 1}`} />
                    </div>
                ))}
            </Slider>

            {/** Session end time */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker 
                    label="Finish time" 
                    // minTime={dayjs(startTime)}
                    value={endTime}
                    onChange={(time) => {setEndTime(time)}}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            InputProps: {
                            sx: {
                                fontSize: '16px', // Increase the font size for the input text
                                textAlign: 'center',
                                '& input': {
                                    textAlign: 'center', // This centers the text inside the input field
                                },
                            },
                            },
                            InputLabelProps: {
                            shrink: true,
                            sx: {
                                fontSize: '12px',
                            },
                            },
                            sx: {
                                marginTop: '15px',
                            },
                        },
                    }}
                />
            </LocalizationProvider>

            {/* Feedback textbox */}    
            <TextField fullWidth style={{ marginTop: "10px" }}
                type="text"
                multiline
                rows={2}
                id="feedback"
                label="Session Feedback"
                variant="outlined"
                value={session.feedback}
                onChange={(e) => handleFieldChange('session_feedback', e)}
            />

            {/* Self reflection textbox */}  
            <TextField fullWidth style={{ marginTop: "10px" }}
                type="text"
                multiline
                rows = {2}
                id="reflection"
                label="Self Reflection"
                variant="outlined"
                value={session.self_reflection}
                onChange={(e) => handleFieldChange('session_reflection', e)}
            />

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Save
                </Button>

                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>
        </div>
    )

};

export default CompleteSession;