import { useState, useEffect } from "react";
import { ToggleButton, ToggleButtonGroup, TextField, FormControl, InputLabel, Select, MenuItem, Button, Autocomplete } from "@mui/material";

const AddTumblingEntry = ({objectives, entryHolder, handleNewEntry, handleCancel}) => {

    // Initialise the entry, be it blank or editing an existing one.
    const [entry, setEntry] = useState(entryHolder); 
    const [customSkills, setCustomSkills] = useState(
        entryHolder.objective.type === 'custom'
            ? entryHolder.objective.id.split('-').map(
                skillId => objectives.find(obj => obj.id === skillId) || null
            )
            : Array(7).fill(null)
    );
    
    const initialRecorded = [];
    entryHolder.execution > 0 && initialRecorded.push('execution');
    entryHolder.landing_penalty !== 999 && initialRecorded.push('landing');
    const [wasRecorded, setWasRecorded] = useState(initialRecorded);   
    const [isBothRecorded, setBothRecorded] = useState(wasRecorded.includes('landing') && wasRecorded.includes('execution'));
    const [leftInputStyle, setLeftInputStyle] = useState(isBothRecorded ? {width: "49.5%", margin: "10px 1% 0 0"} : {width: "100%", marginTop: "10px"});
    const [rightInputStyle, setRightInputStyle] = useState(isBothRecorded ? {width: "49.5%", marginTop: "10px"} : {width: "100%", marginTop: "10px"});

    // filter list of all objectives by type for current entry.
    const objectiveMap = entry.objective.type === 'pass' ? 'pass' : 'skill';
    const [typedObjectives, setTypedObjectives] = useState(objectives.filter(objective => objective.type === objectiveMap));
    
    // Was this a single attempt or multiple
    const [singleOrMultiple, setSingleOrMultiple] = useState(entryHolder.repetitions === 1 ? 'single' : 'multiple');

    useEffect(() => {
        if (entry.objective.type === 'pass') {
            setTypedObjectives(objectives.filter(objective => objective.type === 'pass'));
        }
        else if (entry.objective.type === 'drill') {
            setTypedObjectives(objectives.filter(objective => objective.type === 'drill'));
        }
        else {
            setTypedObjectives(objectives.filter(objective => objective.type === 'skill'));
        }
    }, [entry.objective.type, objectives]);

    useEffect(() => {
        setBothRecorded(wasRecorded.includes('landing') && wasRecorded.includes('execution'));
        setLeftInputStyle(isBothRecorded ? {width: "49.5%", margin: "10px 1% 0 0"} : {width: "100%", marginTop: "10px"});
        setRightInputStyle(isBothRecorded ? {width: "49.5%", marginTop: "10px"} : {width: "100%", marginTop: "10px"});
    }, [wasRecorded, isBothRecorded]);

    // Get difficulty of any objective
    const getDifficulty = () => {
        if (entry.completed) {
            if (entry.objective.type !== 'custom') {
                return objectives.filter(obj => obj.id === entry.objective.id)[0].difficulty;
            }
            else {
                return parseFloat(
                    customSkills.reduce((totalDifficulty, skill) => {
                        return totalDifficulty + (skill?.difficulty || 0);
                    }, 0)
                );
                
            }
        }
        else {
            return getCompletedSkillsDifficulty(entry.objective.id, entry.total_completed_skills);
        }
        
    };

    // Get total dd when not all skills completed
    // TODO Alter for tumbling - this is dmt version
    // const getCompletedSkillsDifficulty = (id, completed) => {
    //     if (completed === 0) {
    //         return 0.0;
    //     }
    //     // If not complete or 0 then must be first skill only
    //     const skillCompleted = String(id.split('-').map(Number)[0]);

    //     return parseFloat(objectives.filter(value => value.id === skillCompleted)[0].difficulty.toFixed(1));
    // };

    // Check if this is working
    function getCompletedSkillsDifficulty(type, id, completed) {
        const objectiveArray = id.split('-').map(Number);
        var movesCompleted = [];
        
        if (type === 'drill') {
            const numRepetitions = parseInt(objectiveArray[1], 10);
            const skillIds = objectiveArray.slice(2);
   
            for (let i = 0; i < numRepetitions; i++) {
                movesCompleted = movesCompleted.concat(skillIds);
            }
        }
        else {
            movesCompleted = objectiveArray;
        }
        movesCompleted = movesCompleted.slice(0, completed);

        return parseFloat(movesCompleted.reduce((total, current) => total + objectives.find((value) => value.id === current.toString()).difficulty, 0).toFixed(1))
    };

    const getTotalSkillsFromObjectiveType = (type, objectiveId) => {
        if (type === 'pass' || type === 'custom') {
            return 8;
        }
        else {
            return parseInt(objectiveId.split('-')[0]) || 8;
        }
    };

    const handleEntryTypeChange = (e) => {
        // Change from pass to skill etc. Reset complete/total skills
        const totSkills = getTotalSkillsFromObjectiveType(e.target.value, '8-1-1');
        const reps = entry.repetitions > 1 ? entry.repetitions : 1;
        setEntry({
            ...entry,
            objective: {type: e.target.value, id: '', name: ''},
            completed: true,
            total_skills: totSkills * reps,
            total_completed_skills: totSkills * reps
        })
    };

    const handleObjectiveChange = (e, newValue) => {
        const totSkills = getTotalSkillsFromObjectiveType(entry.objective.type, newValue.id);
        const reps = entry.repetitions > 1 ? entry.repetitions : 1;
        if (newValue?.id?.length) {
            setEntry((entry) => ({ 
                ...entry, 
                objective: {type: entry.objective.type, id: newValue.id, name: newValue.name},
                total_skills: totSkills * reps,
                total_completed_skills: totSkills * reps
            }));
        }        
    };

    const handleCustomSkillChange = (e, newSkill, index) => {
        setCustomSkills((prevSkills) => {
            const updatedSkills = [...prevSkills];
            updatedSkills[index] = newSkill;
            return updatedSkills;
        });
    };

    const handleSingleOrMultipleChange = (e) => {
        // always use complete when doing multiple so must reset complete, total completed skills and reps
        // also reset ex and landing as don't use these with multiple.
        
        const skillsPerAttempt = getTotalSkillsFromObjectiveType(entry.objective.type, entry.objective.id);
        setEntry({
            ...entry,
            repetitions: 1,
            total_completed_skills: skillsPerAttempt,
            total_skills: skillsPerAttempt,
            completed: true,
            execution: 0,
            landing_penalty: 999
        });
        setWasRecorded([]);
        setSingleOrMultiple(e.target.value);
    }

    const handleRepetitionChange = (e) => {
        let reps = parseInt(e.target.value.trim());
        reps = isNaN(reps) ? 0 : reps;

        if (reps < 0) {
            reps = 0;
        }

        const skillsPerAttempt = getTotalSkillsFromObjectiveType(entry.objective.type, entry.objective.id)
        setEntry({
            ...entry,
            repetitions: String(reps),
            total_completed_skills: reps * skillsPerAttempt,
            total_skills: reps * skillsPerAttempt
        });
    };

    // Successful / unsuccessful toggle
    const handleCompletedChange = () => {
        setEntry({
            ...entry,
            completed: !entry.completed,
            total_completed_skills: !entry.completed ? entry.total_skills : entry.total_skills - 1
        });
    };


    const handleCompletedSkillsChange = (e) => {
        setEntry({
            ...entry,
            total_completed_skills: parseInt(e.target.value)
        });
    };

    // Ex and Landing toggles
    const handleRecordedChange = (e, newRecorded) => {
        let newEntry = {...entry};
        if (!newRecorded.includes('execution')){
            newEntry = {
                ...newEntry,
                execution: 0
            };
        }
        if (!newRecorded.includes('landing')){
            newEntry = {
                ...newEntry,
                landing_penalty: 0
            };
        }
        setEntry(newEntry);
        setWasRecorded(newRecorded);
    };

    // For Ex, Landing
    const handleFloatChange = (fieldName, e, min, max) => {
        let newVal = parseFloat(e.target.value.trim());
        newVal = isNaN(newVal) ? 0 : newVal;

        if (min !== 'none' && newVal < min) {
            newVal = min;
        } else if ( max !== 'none' && newVal > max) {
            newVal = max;
        }
        setEntry((entry) => ({ ...entry, [fieldName]: String(newVal) }));
    };

    const handleLandingChange = (e, min, max) => {
        let newVal = e.target.value === '' ? 999 : parseFloat(e.target.value);
        if (newVal !== '') {
            if (min !== 'none' && newVal < min) {
                newVal = min;
            } else if ( max !== 'none' && newVal > max && newVal !== 999) {
                newVal = max;
            }
        }
        setEntry((entry) => ({ ...entry, landing_penalty: String(newVal) }));
    };
 
    // Handle changes to both feedback and self-reflection fields
    const handleFieldChange = (fieldName, e) => {
        if (e.target.value === '') {
            setEntry((entry) => ({ ...entry, [fieldName]: null }));
        } else {
            setEntry((entry) => ({ ...entry, [fieldName]: e.target.value }));
        }
    };

    const handleSubmit = () => {
        const reps = entry.repetitions < 1 ? 1 : parseInt(entry.repetitions);
        const diff = parseFloat(getDifficulty().toFixed(1));

        if (entry.objective.type === 'custom') {
            setEntry((prevEntry) => {
                const skillIds = customSkills.map(skill => skill?.id || '').join('-');
                const skillNames = customSkills.map(skill => skill?.name || '').join(' -> ');
            
                const updatedEntry = {
                    ...prevEntry, 
                    objective: {
                        ...prevEntry.objective,
                        id: skillIds,
                        name: skillNames,
                    },
                    execution: parseFloat(entry.execution),
                    landing_penalty: parseFloat(entry.landing_penalty),
                    repetitions: reps,
                    total_difficulty: diff,
                    total_load: parseFloat(((diff + entry.total_completed_skills) * reps).toFixed(1)),
                };
                handleNewEntry(updatedEntry);
                return updatedEntry;
            });
        }
        else {
            setEntry((prevEntry) => {
                const updatedEntry = {
                    ...prevEntry, 
                    execution: parseFloat(entry.execution), 
                    landing_penalty: parseFloat(entry.landing_penalty),
                    repetitions: reps,
                    total_difficulty: diff,
                    total_load: parseFloat(((diff + entry.total_completed_skills) * reps).toFixed(1))
                }
                handleNewEntry(updatedEntry);
                return updatedEntry;
            });
        }
    };
    
    return (
        <form className="new-entry">
            {/* Objective type */}
            <FormControl fullWidth style={{marginTop: '10px'}}>
                <InputLabel id="entry-type-label">Entry Type</InputLabel>
                <Select
                    labelId="entry-type-label"
                    id="entry-type-dmt"
                    value={entry.objective.type}
                    label="Entry Type"
                    onChange={handleEntryTypeChange}
                >                    
                    <MenuItem value={'pass'}>Saved Run</MenuItem>        
                    <MenuItem value={'custom'}>Custom Run</MenuItem> 
                    <MenuItem value={'drill'}>Drill</MenuItem>
                </Select>
            </FormControl>

            {/* Objective dropdown */} 
            {entry.objective.type !== 'custom' &&
                <Autocomplete 
                    style={{marginTop: '10px'}} 
                    options={typedObjectives} 
                    getOptionLabel={option => option.name} 
                    isOptionEqualToValue={() => true}
                    value={entry.objective}
                    onChange={(e, newVal) => handleObjectiveChange(e, newVal)}
                    renderInput={params => (
                        <TextField {...params} label={"Search or select a " + entry.objective.type} variant="outlined" />
                    )}
                />
            }
            
            {entry.objective.type === 'custom' && 
                Array.from({ length: 7 }).map((_, index) => (
                    <Autocomplete 
                        key={index}
                        style={{marginTop: '10px'}} 
                        options={typedObjectives} 
                        getOptionLabel={option => option.name || ''} 
                        isOptionEqualToValue={() => true}
                        value={customSkills[index] !== null ? customSkills[index] : ''}
                        onChange={(e, newSkill) => handleCustomSkillChange(e, newSkill, index)}
                        renderInput={params => (
                            <TextField {...params} label={`Search or select skill ${index + 1}`} variant="outlined" />
                        )}
                    />)
                )
            }

            {/* If single or multiple attempts */}
            <ToggleButtonGroup
                fullWidth
                style={{marginTop: '10px'}}
                color="primary"
                value={singleOrMultiple}
                exclusive
                onChange={(e) => handleSingleOrMultipleChange(e)}
                aria-label="Single attempt or multiple"
            >
                <ToggleButton color="primary" value={'single'} aria-label="single">Single Attempt</ToggleButton>
                <ToggleButton color="primary" value={'multiple'} aria-label="multiple">Multiple</ToggleButton>
            </ToggleButtonGroup>


            {singleOrMultiple === 'multiple' &&
                <TextField fullWidth style={{ marginTop: "10px" }}
                    type="number"
                    id="repetitions"
                    label="Repetitions"
                    variant="outlined"
                    value={entry.repetitions}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => handleRepetitionChange(e)}
                />
            }

            {/* Only show these field if single attempt */}
            {singleOrMultiple === 'single' && <>
                {/* Was turn successful */}
                {(entry.objective.id !== '' && entry.objective.type !== 'custom') && <>
                    <ToggleButtonGroup
                        fullWidth
                        style={{marginTop: '10px'}}
                        color="primary"
                        value={entry.completed}
                        exclusive
                        onChange={handleCompletedChange}
                        aria-label="routine success"
                    >
                        <ToggleButton color="success" value={true} aria-label="Complete">Complete</ToggleButton>
                        <ToggleButton color="error" value={false} aria-label="Part Complete">Part Complete</ToggleButton>
                    </ToggleButtonGroup>
                </>}

                {/* Num completed skills if unsuccessful */}
                {(!entry.completed) && <>
                    <FormControl fullWidth style={{ marginTop: "10px" }}>
                        <InputLabel id="skills-completed-label">Skills Completed</InputLabel>
                        <Select
                            labelId="skills-completed-label"
                            label="Skills Completed"
                            variant="outlined"
                            value={entry.total_completed_skills}
                            onChange={handleCompletedSkillsChange}
                        >
                            {[...Array(entry.total_skills).keys()].map((number) => (
                                <MenuItem key={number} value={number}>{number}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>}

                {/* Was execution and landing recorded */}
                <ToggleButtonGroup
                    fullWidth
                    style={{marginTop: '10px'}}
                    color="primary"
                    value={wasRecorded}
                    onChange={handleRecordedChange}
                    aria-label="Recorded scores"
                >
                    <ToggleButton value="execution" aria-label="execution">Execution</ToggleButton>
                    <ToggleButton value="landing" aria-label="landing">Landing</ToggleButton>
                </ToggleButtonGroup>

                {/* Execution number textbox */}
                {wasRecorded.includes('execution') &&                    
                    <TextField style= {leftInputStyle}
                        type="number"
                        id="execution-number"
                        variant="outlined"
                        label="Execution"
                        value={entry.execution}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onBlur={(e) => handleFloatChange('execution', e, 0, 20)}
                        onChange={(e) => handleFieldChange('execution', e)}
                    />
                }

                {/* Landing number textbox */} 
                {wasRecorded.includes('landing') &&                    
                    <TextField style={rightInputStyle}
                        type="number"
                        id="landing-number"
                        variant="outlined"
                        label="Landing"
                        value={parseFloat(entry.landing_penalty) !== 999 ? entry.landing_penalty : ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => handleLandingChange(e, 0, 3)}
                    />
                }
            </>}

            {/* Feedback textbox */}    
            <TextField fullWidth style={{ marginTop: "10px" }}
                type="text"
                multiline
                rows={3}
                variant="outlined"
                id="feedback"
                label="Coaches Feedback"
                value={entry.feedback !== null ? entry.feedback : ''}
                onChange={(e) => handleFieldChange('feedback', e)}
            />

            {/* Self reflection textbox */}  
            <TextField fullWidth  style={{ marginTop: "10px" }}
                type="text"
                multiline
                rows={3}
                variant="outlined"
                id="reflection"
                label="Self Reflection"
                value={entry.self_reflection !== null ? entry.self_reflection : ''}
                onChange={(e) => handleFieldChange('self_reflection', e)}
            />

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    disabled={(entry.objective.type !== 'custom' && entry.objective.id === '') || (entry.objective.type === 'custom' && (customSkills[0] === null || customSkills[1] === null))}
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Save entry
                </Button>
                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>            
        </form>
    )
};

export default AddTumblingEntry;