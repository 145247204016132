import { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

const AddNewSkill = ({skills, handleAdd, handleExit}) => {

    const [newSkill, setNewSkill] = useState({
        in_rig: 0,
        to_pit: 0,
        mat_with_catch: 0,
        to_mat: 0,
        to_bed: 0,
        in_combination: 0,
        in_routine: 0,
        competed: 0 
    });

    return (
        <div style={{position: 'fixed', top: '0', left: '0', right: 0, height: 'calc(100% - 56px)', backgroundColor: 'rgba(160, 160, 160, 0.8)'}}>
            
            <img id="close-modal" src="../img/icon/reject.png" alt='close' style={{right: '20px', top: '40px', border: '3px solid black', borderRadius: '100%'}} onClick={handleExit} />

            <div style={{ width: 'calc(100% - 40px)', position: 'absolute', top: '0', bottom: '0', margin:'auto 20px', height: 'fit-content'}}>
                <div style={{border: '3px solid black', borderRadius: '15px', backgroundColor: '#fff'}}>
                    <h2>New Skills</h2>      
                </div>
                <Autocomplete
                    style={{ margin: '5%', backgroundColor: '#fff' }}
                    options={skills}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={() => true} // Hardcoded to true temporarily
                    onChange={(e, newValue) => setNewSkill({
                        ...newSkill,
                        name: newValue.name,
                        skill_id: newValue.id
                    })}
                    renderInput={(params) => (
                        <TextField {...params} label={"Search or select a skill"} variant="outlined" />
                    )}
                />
                <div className="start-session" style={{backgroundColor: '#fff', marginTop: '10%'}}>
                    <input id="add-skill" type="submit" value={'Add Skill'} onClick={() => handleAdd(newSkill)} />
                </div>
            </div>           
        </div>
    );
};

export default AddNewSkill;