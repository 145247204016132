import { Tab, Tabs, Box, Fab } from "@mui/material";
import { useState, useEffect } from "react";
import AddIcon from '@mui/icons-material/Add'
import axios from "axios";
import Header from "../../components/general/Header.tsx";
import LoadingMessage from "../../components/LoadingMessage.js";
import DeleteConfirmationBox from "../../components/DeleteConfirmationBox.js";
import TabPanel, { a11yProps } from "../../components/general/TabPanel.js";
import GoalEntry from "./components/GoalEntry.js";
import AddGoal from "./components/AddGoal.js";

const Goals = ({ user }) => {

    const userId = user.userId;

    const [state, setState] = useState('loading');
    const [goals, setGoals] = useState([]);
    const [tabValue, setTabValue] = useState(0);

    const [confirmModal, setConfirmModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);


    useEffect(() => {
        fetch(process.env.REACT_APP_API_HOST + `/api/goals/${userId}`)
        .then(response => response.json())
        .then(goals => {
            // Format the date strings in the Goals array
            const formattedGoals = (goals !== null) ? goals.map(goal => {
                return {
                    ...goal,
                    // Format the date fields as dd/mm/yyyy
                    start_date: goal.start_date ? new Date(goal.start_date).toISOString() : '',
                    target_date: goal.target_date ? new Date(goal.target_date).toISOString() : '',
                    original_target_date: goal.original_target_date ? new Date(goal.original_target_date).toISOString() : '',
                    next_review_date: goal.next_review_date ? new Date(goal.next_review_date).toISOString() : null,
                    actual_completed_date: goal.actual_completed_date ? new Date(goal.actual_completed_date).toISOString() : '',
                };
            })
            : [];
            
            setGoals(formattedGoals);
            setState('view');
        })
        .catch(error => {
            console.error('Error fetching goals:', error)
            setState('error')
        })
    }, [userId]); 

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
    };

    const handleConfirmRemove = (id) => {
        setIdToDelete(id);
        setConfirmModal(true);
    }

    const handleDelete = (goal_id) => {
        axios.delete(process.env.REACT_APP_API_HOST + `/api/goals/${userId}/${goal_id}`)
        .then(() => {
            setGoals(goals.filter(item => item.id !== goal_id));
            setConfirmModal(false);
            setIdToDelete(0);
        })
        .catch(error => {
            console.error('Error deleting goal', error);
        });
    }

    const emptyGoal = {
        user_id: userId,
        title: null,
        description: null,
        goal_type: "",
        status: "",
        start_date: new Date().toISOString(),
        target_date: new Date().toISOString(),
        next_review_date: new Date().toISOString(),
        actual_completed_date: new Date().toISOString(),
        rag: "",
        reviews: []
    };

    const handleSubmitGoal = (goal) => {
        axios.post(`${process.env.REACT_APP_API_HOST}/api/goals`, goal)
        .then(response => {
            const { last_id } = response.data;
            const goalWithId = {...goal, id: last_id};
            setGoals([goalWithId, ...goals]);
            setState('view');
        })
        .catch(error => {
            console.error('Error adding goal review', error);
        });
    }


    const cancelEntry = () => {
        setState('view');
        setConfirmModal(false);
        setIdToDelete(0);
    };

    if (state === 'error') {
        return <LoadingMessage title={'Error'} />
    }

    if (state === 'loading') {
        return <LoadingMessage title={'Goals'} />
    }

    return (
        <>
            {state === 'view' && <>

                <Header title={'Goals'} />

                <Box sx={{ width: '100%', backgroundColor: 'white', borderRadius: '10px', borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="In Progress" sx={{width: '50%', fontSize: '1.4rem'}} {...a11yProps(0)}  />
                        <Tab label="Completed" sx={{width: '50%', fontSize: '1.4rem'}} {...a11yProps(1)} />
                    </Tabs>
                </Box>
                
                <TabPanel value={tabValue} index={0}>
                    <div className='entries-in-tabs'>
                        {goals.filter(goal => goal.status !== 'complete')?.map((goal) => (
                            <GoalEntry key={goal.id} goal={goal} remove={handleConfirmRemove} />
                        ))} 
                    </div>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                <div className='entries-in-tabs'>
                        {goals.filter(goal => goal.status === 'complete')?.map((goal) => (
                            <GoalEntry key={goal.id} goal={goal} remove={handleConfirmRemove} />
                        ))} 
                    </div>
                </TabPanel>
            </>}

            {state === 'entering' && 
                <AddGoal goalHolder={emptyGoal} handleSubmit={handleSubmitGoal} handleCancel={cancelEntry} />
            }
            
            {(state === 'view') &&
                <Fab color="secondary" aria-label="add" onClick={() => { setState('entering')}} style={{ position: 'absolute', bottom: '20px', right: '0px' }}>
                    <AddIcon />
                </Fab>
            }    

            {confirmModal === true && 
                <DeleteConfirmationBox message={"Are you sure you want to delete this goal?"} handleConfirm={() => handleDelete(idToDelete)} handleCancel={cancelEntry}/>
            }
        </>
    )
};

export default Goals;