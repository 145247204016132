import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { DataProvider } from "./DataContext";
import Layout from "./pages/Layout";
import UserHome from "./pages/UserHome";
import DiarySelection from "./pages/diary/DiarySelection";
import Diary from "./pages/diary/Diary";
import Goals from "./pages/goals/Goals";
import Goal from "./pages/goals/Goal";
import Drills from "./pages/drills/Drills";
import Routines from "./pages/routines/Routines";
import Skills from "./pages/skills/Skills";
import Events from "./pages/events/Events";
import Conditioning from "./pages/conditioning/Conditioning";
import Resources from "./pages/resources/Resources";
import Dashboard from "./pages/insights/Insights";
import Settings from "./pages/settings/Settings";
import NoPage from "./pages/NoPage"
import './reset.css';
import './App.css';
import { Amplify } from 'aws-amplify';
//import { Authenticator } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import ConditioningSession from "./pages/conditioning/components/ConditioningSession";
import ConditioningTestSession from "./pages/conditioning/components/testing/ConditioningTestSession";
Amplify.configure(awsExports);

function App({ user, signOut }) {

  const [existsActiveSession, setExistsActiveSession] = useState(false);

  const handleActiveSessionChange = (newVal) => {
    setExistsActiveSession(newVal);
  };

  return (
      <DataProvider>
        <div className="wrapper-main">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index path="/" element={<UserHome user={user} />} />
                <Route path="/diary" element={<DiarySelection existsActiveSession={existsActiveSession} handleActiveSessionChange={handleActiveSessionChange} user={user} />} />
                <Route path="/diary-entry" element={<Diary user={user} handleActiveSessionChange={handleActiveSessionChange} />} />
                <Route path="/goals" element={<Goals user={user} />} />
                <Route path="/goal" element={<Goal user={user} />} />
                <Route path="/drills" element={<Drills user={user} />} />
                <Route path="/routines" element={<Routines user={user} />} />
                <Route path="/events" element={<Events user={user} />} />
                <Route path="/resources" element={<Resources />} />
                <Route path="/skills" element={<Skills user={user} />} />
                <Route path="/conditioning" element={<Conditioning user={user} />} /> 
                <Route path="/conditioning/session" element={<ConditioningSession user={user} />} /> 
                <Route path="/conditioning/test-session" element={<ConditioningTestSession user={user} />} /> 
                <Route path="/data" element={<Dashboard user={user} />} />
                <Route path="/settings" element={<Settings userId={user.userId} signOut={signOut} />} />
                <Route path="*" element={<NoPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
      </DataProvider>
  );
}
export default withAuthenticator(App);


// function App() {

//   return (
    // <DataProvider>
    //   <div className="wrapper-main">
    //     <BrowserRouter>
    //       <Routes>
    //         <Route index element={<Home />} />
    //         <Route path="/" element={<Layout />}>
    //           <Route path="/userhome" element={<UserHome />} />
    //           <Route path="/mood" element={<MoodScreen defaultSlide={3} />} />
    //           <Route path="/diary" element={<DiarySelection />} />
    //           <Route path="/diary-entry/:session_id" element={<Diary />} />
    //           <Route path="/drills" element={<Drills />} />
    //           <Route path="/routines" element={<Routines />} />
    //           <Route path="/resources" element={<Resources />} />
    //           <Route path="*" element={<NoPage />} />
    //         </Route>
    //       </Routes>
    //     </BrowserRouter>
    //   </div>
    // </DataProvider>
//   );
// }

// export default withAuthenticator(App);

// function App() {
//   return (
//     <div className="wrapper-main">
//       <Authenticator>
//           {({ signOut, user }) => (
//                <DataProvider>
              
//               <BrowserRouter>
//                 <Routes>
//                   <Route path="/" element={<Layout />}>
//                     <Route index path="/" element={<UserHome user={user} signOut={signOut} />} />
//                     <Route path="/mood" element={<MoodScreen defaultSlide={3} />} />
//                     <Route path="/diary" element={<DiarySelection user={user} />} />
//                     <Route path="/diary-entry/:session_id" element={<Diary user={user} />} />
//                     <Route path="/drills" element={<Drills user={user} />} />
//                     <Route path="/routines" element={<Routines user={user} />} />
//                     <Route path="/resources" element={<Resources />} />
//                     <Route path="*" element={<NoPage />} />
//                   </Route>
//                 </Routes>
//               </BrowserRouter>

//           </DataProvider>
//           )}
//       </Authenticator>
//     </div>
//   );
// }

// export default App;