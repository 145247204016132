import { useState } from "react";
import { TextField, Button } from "@mui/material";

const CreateCustomExercise = ({handleSubmit, handleCancel}) => {

    const [exercise, setExercise] = useState('');

    return (
        <div style={{backgroundColor: '#fff', padding: '0 5px', border: "2px solid grey", borderRadius: '15px', position: 'absolute', top: '0', bottom: '10%', margin:'auto 0', height: 'fit-content', width: '100%', zIndex: '1000'}}>
            <h2>Custom Exercise</h2>
            <TextField fullWidth style={{marginTop: '15px'}}
                type="text"
                id="Exercise"
                label="Exercise"
                variant="outlined"
                value={exercise}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => setExercise(e.target.value)}
            />

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={() => handleSubmit(exercise)}
                    variant="contained"
                >
                    Add
                </Button>

                <Button
                    style={{margin: "15px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div>
        </div>
    )
}

export default CreateCustomExercise;