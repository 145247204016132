import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import dayjs from 'dayjs';

const SessionEntry = ({session}) => {

    const [state, setState] = useState(false);
    const navigate = useNavigate();

    const handleParentClick = () => {
        navigate("/diary-entry", { state: { session }});
    };

    const handleButtonClick = (event) => {
        event.stopPropagation();
        setState(!state);
    };

    const getFormattedDuration = (start, end) => {
        const durationInMins = Math.floor((end - start) / 60000);
        const hours = Math.floor(durationInMins / 60);
        const mins = durationInMins % 60;
        return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`
    };

    const formattedDuration = getFormattedDuration(new Date(session.session_date), new Date(session.end_time));

    

    return (
        <div className='entry' style={{cursor: 'pointer', position: 'relative', border: '2px solid white', borderRadius: '10px', padding: '5px', margin: '5px 0'}} onClick={handleParentClick} >
            <h3 style={{textAlign: 'center'}}>{dayjs(session.session_date).format("DD/MM/YY") + ' - ' + session.discipline}</h3>
            {(session.status === 'closed' && state) && <>
                {session.end_time !== null &&
                    <p>Session length: {formattedDuration}</p>
                }
                <p>Total efforts:  {session.total_exercises}</p>
                <p>Total skills:  {session.total_skills}</p>
                <p>Total difficulty:  {session.total_difficulty}</p>
                {/* <p>Total load:  {session.total_load}</p> */}
                {session.exercise_success_rate > 0.0 &&
                    <p>Exercise completion:  {session.exercise_success_rate}%</p>
                }
                {session.skills_success_rate > 0.0 &&
                    <p>Skills completion:  {session.skills_success_rate}%</p>
                }
                {session.average_tof > 0.0 &&
                    <p>Average ToF:  {session.average_tof}s</p>
                }
                {session.average_stopwatch > 0.0 &&
                    <p>Average Stopwatch ToF:  {session.average_stopwatch}s</p>
                }
                {session.hydration > 0 &&
                    <p>Water consumption: {session.hydration}ml</p>
                }
            </>}

            {session.status === 'closed' &&
            <Button style={{fontSize: '12px', color: '#000'}} onClick={handleButtonClick}>
                {!state ? 'Show summary' : 'Hide summary'}
            </Button>}
        </div>
    )
};

export default SessionEntry;