import { useEffect, useState } from 'react';
import axios from 'axios';
import { Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import LoadingMessage from '../../../components/LoadingMessage';
import DeleteConfirmationBox from '../../../components/DeleteConfirmationBox';
import ConditioningSessionEntry from './ConditioningSessionEntry';
import AddConditioningSession from './AddConditioningSession';

const ConditioningSessions = ({ user }) => {

    const userId = user.userId;
    const [sessions, setSessions] = useState([]);
    const [state, setState] = useState('loading');

    const [confirmModal, setConfirmModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);

    const emptySession = {
        user_id: userId,
        title: "",
        start_time: new Date().toISOString()
    }

    useEffect(() => {
        fetch(process.env.REACT_APP_API_HOST + `/api/conditioning/sessions/${userId}`)
        .then(response => response.json())
        .then(data => {
            if (data !== null) {
                setSessions(data);
            }
            setState('view');
        })
        .catch(error => console.error('Error fetching user conditioning sessions:', error));

    }, [userId]);

    const handleNewSession = (newSession) => {

        axios.post(process.env.REACT_APP_API_HOST + '/api/conditioning/sessions', newSession, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            // Extract session ID from the response
            const { last_id } = response.data;
            let newEntryWithId = {...newSession, id: last_id};
            setSessions([newEntryWithId, ...sessions]);
            setState('view');
        })
        .catch(error => {
            console.error('Error posting new conditioning session:', error);
        });  
    }

    // const handleUpdate = (updatedSession) => {        
    //     axios.post(process.env.REACT_APP_API_HOST + '/api/conditioning/sessions', updatedSession, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     })
    //     .then(() => {
    //         setSessions(sessions.map(session => {
    //             if (session.id === updatedSession.id) {
    //                 return updatedSession;
    //             }
    //             else {
    //                 return session;
    //             }
    //         }));
    //         setState('view');
    //     })
    //     .catch(error => {
    //         console.error('Error updating conditioning session:', error);
    //     });  
    // }

    const handleConfirmRemove = (id) => {
        setIdToDelete(id);
        setConfirmModal(true);
    }

    const handleDelete = (session_id) => {
        axios.delete(process.env.REACT_APP_API_HOST + `/api/conditioning/sessions/${userId}/${session_id}`)
        .then(() => {
            setSessions(sessions.filter(item => item.id !== session_id));
            setConfirmModal(false);
            setIdToDelete(0);
        })
        .catch(error => {
            console.error('Error deleting conditioning session', error);
        });
    }

    const cancelEntry = () => {
        setState('view');
        setConfirmModal(false);
        setIdToDelete(0);
    }

    // if (state === 'error') {
    //     return <LoadingMessage title={'Error'} />
    // }

    if (state === 'loading') {
        return <LoadingMessage title={'Conditioning'} />
    }

    return (
        <>
            {state === 'view' && <>
                <div className='entries-in-tabs'>
                    {sessions.map((session) => (
                        <ConditioningSessionEntry key={session.id} session={session} remove={handleConfirmRemove} />
                    ))} 
                </div>
            </>}

            {state === 'entering' && 
                <AddConditioningSession sessionHolder={emptySession} handleSubmit={handleNewSession} handleCancel={cancelEntry} />
            }
            
            {(state === 'view') &&
                <Fab color="secondary" aria-label="add" onClick={() => { setState('entering')}} style={{ position: 'absolute', bottom: '20px', right: '0px' }}>
                    <AddIcon />
                </Fab>
            }    

            {confirmModal === true && 
                <DeleteConfirmationBox message={"Are you sure you want to delete this session?"} handleConfirm={() => handleDelete(idToDelete)} handleCancel={cancelEntry}/>
            }
        </>
    )
}

export default ConditioningSessions;