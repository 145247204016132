import { Box } from "@mui/material";
import React from "react";

interface IInputProps {
    title: string
}

const Header = (props: IInputProps) => {
    return (
        <Box sx={{fontSize: '40px', textAlign: 'center', height: '60px' }}>
            {props.title}
        </Box>
    )
}

export default Header;