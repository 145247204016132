import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { FormControl, FormControlLabel, FormGroup, Checkbox, Grid } from '@mui/material';
import LoadingMessage from "../../components/LoadingMessage.js";
import Header from '../../components/general/Header.tsx';

const Dashboard = ({ user }) => {
    
    const userId = user.userId !== null ? user.userId : "11111-11111-11111-11111";
    const [selectedMetrics, setSelectedMetrics] = useState({
        total_score: true,
        execution: false,
        hd: false,
        tof: false,
        difficulty: false
    });

    const [jsonData,  setJsonData] = useState(null);
    const [yDomain, setYDomain] = useState(null);

    

     useEffect(() => {
        fetch(process.env.REACT_APP_API_HOST + `/api/results/trampoline/${userId}`)
            .then(response => response.json())
            .then(data => {
                if (data !== null) {
                    setJsonData(data.map(entry => ({
                        ...entry,
                        result_date: new Date(entry.result_date).toLocaleDateString('en-GB') // Format date
                    })).sort((a, b) => new Date(b.result_date) - new Date(a.result_date)));
                }
            })
            .catch(error => console.error('Error fetching trampoline results: ', error)
        );
    }, [userId]);

    useEffect(() => {
        const getYDomain = () => {
            const selectedData = Object.keys(selectedMetrics)
                .filter(metric => selectedMetrics[metric])
                .flatMap(metric => jsonData.map(entry => entry[metric]));
            const minY = Math.ceil(Math.min(...selectedData) - 1); // Adjust as needed for padding
            const maxY = Math.floor(Math.max(...selectedData) + 1); // Adjust as needed for padding
    
            return [minY, maxY];
        };
        if (jsonData !== null) {
            setYDomain(getYDomain());
        }
    },[jsonData, selectedMetrics]);

    const handleMetricChange = (event) => {
        const { name, checked } = event.target;

        if (name === 'total_score' && checked) {
            setSelectedMetrics({
                total_score: true,
                execution: false,
                hd: false,
                tof: false,
                difficulty: false
            });
        } else {
            setSelectedMetrics((prevMetrics) => ({
                ...prevMetrics,
                total_score: false,
                [name]: checked
            }));
        }
    };

    if (jsonData === null) {
        return <LoadingMessage title={'Insights'} />
    }

    return (<>
        <Header title={'Insights'} />
        
        <div style={{ padding: '20px', backgroundColor: '#fff'  }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormGroup row>
                            {Object.keys(selectedMetrics).map((metric) => (
                                <FormControlLabel
                                    key={metric}
                                    control={
                                        <Checkbox
                                            checked={selectedMetrics[metric]}
                                            onChange={handleMetricChange}
                                            name={metric}
                                            color="primary"
                                        />
                                    }
                                    label={metric.replace('_', ' ').toUpperCase()}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart data={jsonData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                dataKey="result_date" 
                                tick={{ fontSize: 14 }} 
                                label={{ value: "Date", position: "insideBottom", fontSize: 16, dy: 8 }} 
                                reversed={true} // Reverse the x-axis direction
                            />
                            <YAxis 
                                domain={yDomain} 
                                tick={{ fontSize: 14 }} 
                                label={{ value: "Score", angle: -90, position: "insideLeft", fontSize: 16 }} 
                            />
                            <Tooltip 
                                formatter={(value, name) => [`${name.replace('_', ' ')}: ${value}`]}
                                labelFormatter={(label) => `Date: ${label}`}
                                contentStyle={{ fontSize: 14 }}
                                labelStyle={{ fontSize: 16 }}
                                itemStyle={{ fontSize: 16 }}
                            />
                            <Legend wrapperStyle={{ fontSize: '16px' }} />
                            {Object.keys(selectedMetrics).map((metric, index) =>
                                selectedMetrics[metric] && (
                                    <Line
                                        key={metric}
                                        type="monotone"
                                        dataKey={metric}
                                        stroke={`hsl(${index * 60}, 70%, 50%)`} // Dynamic color for each line
                                        activeDot={{ r: 8 }}
                                    />
                                )
                            )}
                        </LineChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        </div>
        </>
    );
};

export default Dashboard;

