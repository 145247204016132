import { useState, useEffect } from "react";
import axios from "axios";
import { Tab, Tabs, Box, Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add'
import AddRoutine from "./components/AddRoutine";
import RoutineEntry from "./components/RoutineEntry";
import AddPass from "./components/AddPass";
import PassEntry from "./components/PassEntry";
import AddTumbleRun from "./components/AddTumbleRun.js";
import TumbleEntry from "./components/TumbleEntry.js";
import DeleteConfirmationBox from "../../components/DeleteConfirmationBox";
import { useData } from "../../DataContext";
import LoadingMessage from "../../components/LoadingMessage";
import Header from "../../components/general/Header.tsx";
import TabPanel, { a11yProps } from "../../components/general/TabPanel.js";

const Routines = ({ user }) => {

    const userId = user.userId;
    const [userGender, setUserGender] = useState(null);

    const blankRoutine = {
        user_id: userId,
        name: '',
        status: 'active',
        routine: {
            routine_id: '1-1-1-1-1-1-1-1-1-1',
            skills: [1,1,1,1,1,1,1,1,1,1], 
            difficulty: 0.0,
            new_difficulty: 0.0
        }
    };

    const blankPass = {
        user_id: userId,
        name: '',
        status: 'active',
        pass: {
            pass_id: '3-3',
            skills: [3,3], 
            difficulty: 0.0
        }
    };

    const blankTumble = {
        user_id: userId,
        name: '',
        status: 'active',
        routine: {
            pass_id: '1-4-4-4-4-4-4-5',
            skills: [1,4,4,4,4,4,4,5], 
            difficulty: 1.8
        }
    };

    const [state, setState] = useState('loading');
    const [confirmModal, setConfirmModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);
    const [routinePlaceholder, setRoutinePlaceholder] = useState(blankRoutine);
    const [routines, setRoutines] = useState([]);
    const [passPlaceholder, setPassPlaceholder] = useState(blankPass);
    const [passes, setPasses] = useState([]);
    const [tumblePlaceholder, setTumblePlaceholder] = useState(blankTumble);
    const [tumbles, setTumbles] = useState([]);
    const [tabValue, setTabValue] = useState(0);

    const { skillsList } = useData();
    const [dmtSkillsList, setDmtSkillsList] = useState([]);
    const [tumblingSkillsList, setTumblingSkillsList] = useState([]);

    useEffect(() => {

        fetch(process.env.REACT_APP_API_HOST + `/api/user-routines/${userId}`)
        .then(response => response.json())
        .then(data => {
            if (data !== null) {
                setRoutines(data);
            }
        })
        .catch(error => console.error('Error fetching user routines: ', error));

        fetch(process.env.REACT_APP_API_HOST + `/api/user-passes/${userId}`)
        .then(response => response.json())
        .then(data => {
            if (data !== null) {
                setPasses(data);
            }
        })
        .catch(error => console.error('Error fetching user tumbles: ', error));

        fetch(process.env.REACT_APP_API_HOST + `/api/routines/tumbling/${userId}`)
        .then(response => response.json())
        .then(data => {
            if (data !== null) {
                setTumbles(data);
            }
        })
        .catch(error => console.error('Error fetching user passes: ', error));

        fetch(process.env.REACT_APP_API_HOST + `/api/user/${userId}`)
        .then(response => response.json())
        .then(data => {
            if (data !== null) {
                setUserGender(data.gender);
            }
        })
        .catch(error => console.error('Error fetching user passes: ', error));

        fetch(process.env.REACT_APP_API_HOST + '/api/skills/dmt')
        .then(response => response.json())
        .then(skillsList => {
            setDmtSkillsList(skillsList.filter(val => (val.id > 2)));
        })
        .catch(error => console.error('Error fetching all dmt skills:', error));

        fetch(process.env.REACT_APP_API_HOST + '/api/skills/tumbling')
        .then(response => response.json())
        .then(skills => {
            setTumblingSkillsList(skills);
        })
        .catch(error => console.error('Error fetching all tumbling skills:', error));

        setState('view');
    }, [userId]); 

    const handleNewRoutine = (discipline, newRoutine) => {
        let endpoint = 'user-routines';
        switch (discipline) {
            case 'dmt':
                endpoint = 'user-passes';
                break;
            case 'tumbling':
                endpoint = 'routines/tumbling';
                break;
            default:
                endpoint = 'user-routines'
                break;
        };

        if (state === 'entering-tri' || state === 'entering-dmt' || state === 'entering-tum') {
            axios.post(process.env.REACT_APP_API_HOST + `/api/${endpoint}`, newRoutine, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => {
                if (endpoint === 'user-routines') {setRoutines([{...newRoutine, id: res.data.last_id}, ...routines])}
                else if (endpoint === 'user-passes') {setPasses([{...newRoutine, id: res.data.last_id}, ...passes])}  
                else if (discipline === 'tumbling') {setTumbles([{...newRoutine, id: res.data.last_id}, ...tumbles])}         
            })
            .catch(error => {
                console.error('Error posting trampoline entry details:', error);
            }); 
        } else {
            axios.patch(process.env.REACT_APP_API_HOST + `/api/${endpoint}`, newRoutine, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                if (endpoint === 'user-routines') {
                    setRoutines(routines.map(routine => {
                        if (routine.id === newRoutine.id) {
                            return newRoutine;
                        }
                        return routine;
                    }));
                }
                else if (endpoint === 'user-passes') {
                    setPasses(passes.map(pass => {
                        if (pass.id === newRoutine.id) {
                            return newRoutine;
                        }
                        return pass;
                    }));
                }  
                else if (discipline === 'tumbling') {
                    setTumbles(tumbles.map(tumble => {
                        if (tumble.id === newRoutine.id) {
                            return newRoutine;
                        }
                        return tumble;
                    }));
                }   
            })
            .catch(error => {
                console.error('Error posting routine details:', error);
            }); 
        }
        setState('view');
    };

    const handleEditing = (discipline, routine) => {
        switch (discipline) {
            case 'trampoline':
                setRoutinePlaceholder(routine);
                setState('editing-tri');
                break;
            case 'dmt':
                setPassPlaceholder(routine);
                setState('editing-dmt');
                break;
            case 'tumbling':
                setTumblePlaceholder(routine);
                setState('editing-tum');
                break;
            default:
                console.log('Discipline not recognised');
        };
        
    }

    const handleConfirmRemove = (id) => {
        setIdToDelete(id);
        setConfirmModal(true);
    }

    const removeEntry = (id) => {
        let endpoint = 'user-routines';
        switch (tabValue) {
            case 1:
                endpoint = 'user-passes';
                break;
            case 2:
                endpoint = 'routines/tumbling';
                break;
            default:
                endpoint = 'user-routines'
                break;
        };

        axios.delete(process.env.REACT_APP_API_HOST + `/api/${endpoint}/${userId}/${id}`)
        .then(() => {
            if (endpoint === 'user-routines') {setRoutines(routines.filter(item => item.id !== id))}
            else if (endpoint === 'user-passes') {setPasses(passes.filter(item => item.id !== id))}
            else if (endpoint === 'routines/tumbling') {setTumbles(tumbles.filter(item => item.id !== id))}
            
        })
        .catch(error => {
            console.error('Error deleting routine', error);
        });
        setConfirmModal(false);
        setIdToDelete(0);
    };
    
    const cancelEntry = () => {
        setState('view');
        setConfirmModal(false);
        setIdToDelete(0);
    };

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
    };

    if (!skillsList.length || !dmtSkillsList.length || state === 'loading') {
        return <LoadingMessage title={'Routines'} />
    }

    return (
        <>
            <Header title={state === 'view' ? 'Routines' : '  Add Routine'} />

            <Box sx={{ width: '100%', backgroundColor: 'white', borderRadius: '10px', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Trampoline" sx={{width: '33%', fontSize: '1.4rem'}} {...a11yProps(0)}  />
                    <Tab label="DMT" sx={{width: '33%', fontSize: '1.4rem'}} {...a11yProps(1)} />
                    <Tab label="Tumbling" sx={{width: '33%', fontSize: '1.4rem'}} {...a11yProps(2)} />
                </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
                {(state === 'view' && routines.length > 0) &&
                    <div className='entries-in-tabs'>
                        {routines.map((routine, index) => (
                            <RoutineEntry key={index}  skillsList={skillsList} routine={routine} index={index} setEditing={handleEditing} remove={handleConfirmRemove} />
                        ))}
                    </div>
                } 
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                {(state === 'view' && passes.length > 0) &&
                <div className='entries-in-tabs'>
                    {passes.map((pass, index) => (
                        <PassEntry key={index}  skillsList={dmtSkillsList} pass={pass} index={index} setEditing={handleEditing} remove={handleConfirmRemove} />
                    ))}
                </div>
                } 
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                {(state === 'view' && tumbles.length > 0) &&
                <div className='entries-in-tabs'>
                    {tumbles.map((routine, index) => (
                        <TumbleEntry key={index}  skillsList={tumblingSkillsList} routine={routine} index={index} setEditing={handleEditing} remove={handleConfirmRemove} />
                    ))}
                </div>
                } 
            </TabPanel>

            {(state === 'entering-tri' || state === 'editing-tri') &&
                <AddRoutine type={state} routineHolder={routinePlaceholder} skillsList={skillsList} handleNewEntry={handleNewRoutine} handleCancel={cancelEntry} gender={userGender} />
            }

            {(state === 'entering-dmt' || state === 'editing-dmt') &&
                <AddPass type={state} passHolder={passPlaceholder} skillsList={dmtSkillsList} handleNewEntry={handleNewRoutine} handleCancel={cancelEntry} />
            }

            {(state === 'entering-tum' || state === 'editing-tum') &&
                <AddTumbleRun type={state} routineHolder={tumblePlaceholder} skillsList={tumblingSkillsList} handleNewEntry={handleNewRoutine} handleCancel={cancelEntry} />
            }

            { confirmModal === true && 
                <DeleteConfirmationBox message={"Are you sure you want to delete this routine?"} handleConfirm={() => removeEntry(idToDelete)} handleCancel={cancelEntry}/>
            }

            {state === 'view' &&
                <Fab color="secondary" aria-label="add" style={{ position: 'absolute', bottom: '20px', right: '0' }}
                    onClick={() => {if (tabValue === 0) {setRoutinePlaceholder(blankRoutine); setState('entering-tri')}
                            else if (tabValue === 1) {setPassPlaceholder(blankPass); setState('entering-dmt')}
                            else if (tabValue === 2) {setTumblePlaceholder(blankTumble); setState('entering-tum')}
                            }} 
                    >
                    <AddIcon />
                </Fab>
            }
        </>
    )
}

export default Routines;