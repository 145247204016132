import { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { IconButton, Typography, TextField, MenuItem, Select, FormControl, InputLabel, Grid, Paper, Fab, Box, styled } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteConfirmationBox from "../../components/DeleteConfirmationBox.js";
import GoalReviewEntry from './components/GoalReviewEntry';
import AddGoalReview from './components/AddGoalReview.js';

const ragColors = {
    red: 'rgba(255, 0, 0, 0.3)', // Light translucent red
    amber: 'rgba(255, 223, 0, 0.3)', // Light translucent amber/orange
    green: 'rgba(0, 128, 0, 0.3)' // Light translucent green
};

const StyledSelect = styled(Select)(({ value }) => ({
    backgroundColor: value ? ragColors[value] : 'transparent',
}));


const Goal = ({ user }) => {
    const userId = user.userId;
    const location = useLocation();
    const [goal, setGoal] = useState(location.state.goal);
    const [state, setState] = useState('view');
    const [confirmModal, setConfirmModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);
    const [isDirty, setIsDirty] = useState(false); //for tracking when to update


    const [revDateWidth, setRevDateWidth] = useState(
        goal.status === 'complete' ? {width: "49.5%", marginRight: "1%"} : {width: "100%"}
    )

    useEffect(() => {
        setRevDateWidth(goal.status === 'complete' ? {width: "49.5%", marginRight: "1%"} : {width: "100%"});
    }, [goal.status, revDateWidth])

    const handleInputChange = (field, value) => {
        setGoal({ ...goal, [field]: value });
        if (field === 'status' && value === 'complete' && (goal.actual_completed_date === null || goal.actual_completed_date === '')) {
            setGoal({...goal, actual_completed_date: new Date().toISOString()});
        }
        setIsDirty(true);
    };

    const handleEditGoal = () => {
        const goalToSub = {
            ...goal,
            actual_completed_date: goal.actual_completed_date === '' ? null : goal.actual_completed_date
        };
        axios.put(`${process.env.REACT_APP_API_HOST}/api/goals`, goalToSub)
        .then(() => setIsDirty(false))
        .catch(error => {
            console.error('Error adding goal review', error);
        });
    };

    const handleConfirmRemoveReview = (id) => {
        setIdToDelete(id);
        setConfirmModal(true);
    };

    const handleDeleteReview = (review_id) => {
        axios.delete(`${process.env.REACT_APP_API_HOST}/api/goals/review/${userId}/${goal.id}/${review_id}`)
        .then(() => {
            const newReviews = goal.reviews.filter(rev => rev.id !== review_id) || [];
            setGoal({
                ...goal,
                reviews: newReviews
            });
            setConfirmModal(false);
            setIdToDelete(0);
        })
        .catch(error => {
            console.error('Error deleting goal review', error);
        });

    };

    const emptyReview = {
        goal_id: goal.id,
        user_id: userId,
        review: "",
        review_date: new Date().toISOString(),
        rag: ""
    };

    const [reviewHolder, setReviewHolder] = useState(emptyReview);

    const handleEditReview = (review_id) => {
        setReviewHolder(goal.reviews.filter(rev => rev.id === review_id)[0]);
        setState('editing');
    };

    const handleSubmitReview = (review) => {
        if (state === 'entering') {
            axios.post(`${process.env.REACT_APP_API_HOST}/api/goals/review`, review)
            .then(response => {
                const { last_id } = response.data;
                const reviewWithId = {...review, id: last_id};
                let newReviewsList = [];
                if ('reviews' in goal) {
                    newReviewsList = goal.reviews === null ? [reviewWithId] : [reviewWithId, ...goal.reviews];
                }
                else {
                    newReviewsList = [reviewWithId];
                }
                                
                setGoal({
                    ...goal,
                    reviews: newReviewsList
                })

            })
            .catch(error => {
                console.error('Error adding goal review', error);
            });
        }
        else if (state === 'editing') {
            axios.put(`${process.env.REACT_APP_API_HOST}/api/goals/review`, review)
            .then(() => {

                const newReviewsList = goal.reviews.map(rev => {
                    if (rev.id === review.id) {
                        return review
                    }
                    return rev
                });
                setGoal({
                    ...goal,
                    reviews: newReviewsList
                })

            })
            .catch(error => {
                console.error('Error adding goal', error);
            });
        }
        setState('view');
    }

    const cancelEntry = () => {
        setState('view');
        setConfirmModal(false);
        setIdToDelete(0);
    };

    return (<>
        {state === 'view' && <>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100dvh - 76px)' }}> {/* Adjust for bottom navigation */}
                {/* Goal Details Section */}
                <Paper sx={{ flexShrink: 0, maxHeight: '70%', overflow: 'auto', mb: 1, p: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ position: 'relative' }}>
                            <Link to="/goals">
                                <IconButton
                                    style={{ position: 'absolute', top: '16px', left: '15px', padding: '0', fontSize: "25px", color: "#000" }}
                                    aria-label="back"
                                    size="large"
                                    alt='back'
                                >
                                    <ArrowBackIcon color="black" fontSize="inherit" />
                                </IconButton>
                            </Link>
                            {isDirty &&
                                <IconButton
                                    style={{ position: 'absolute', top: '16px', right: '0px', padding: '0', fontSize: "25px", color: "#000" }}
                                    aria-label="back"
                                    size="large"
                                    alt='back'
                                    disabled={goal.status === 'complete' && (goal.actual_completed_date === null || goal.actual_completed_date === '')}
                                    onClick={handleEditGoal}
                                >
                                    <SaveIcon color="black" fontSize="inherit" />
                                </IconButton>
                            }
                            <Typography variant="h4" align="center">{goal.title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Description"
                                multiline
                                disabled
                                rows={4}
                                fullWidth
                                value={goal.description || ''}
                                onChange={(e) => handleInputChange('description', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl style={{width: '49.5%', marginRight: '1%'}}>
                                <InputLabel>Goal Type</InputLabel>
                                <Select
                                    value={goal.goal_type || ''}
                                    onChange={(e) => handleInputChange('goal_type', e.target.value)}
                                >
                                    <MenuItem value="performance">Performance</MenuItem>
                                    <MenuItem value="outcome">Outcome</MenuItem>
                                    <MenuItem value="process">Process</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl style={{width: '49.5%'}}>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    value={goal.status || ''}
                                    onChange={(e) => handleInputChange('status', e.target.value)}
                                >
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value="in progress">In Progress</MenuItem>
                                    <MenuItem value="complete">Complete</MenuItem>
                                    <MenuItem value="overdue">Overdue</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField style={{width: '49.5%', marginRight: '1%'}}
                                label="Start Date"
                                type="date"
                                fullWidth
                                value={goal.start_date.split('T')[0]}
                                onChange={(e) => handleInputChange('start_date', new Date(e.target.value).toISOString())}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField style={{width: '49.5%'}}
                                label="Target Date"
                                type="date"
                                fullWidth
                                value={goal.target_date.split('T')[0]}
                                onChange={(e) => handleInputChange('target_date', new Date(e.target.value).toISOString())}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField style={revDateWidth}
                                label="Next Review Date"
                                type="date"
                                fullWidth
                                value={goal.next_review_date.split('T')[0]}
                                onChange={(e) => handleInputChange('next_review_date', new Date(e.target.value).toISOString())}
                                InputLabelProps={{ shrink: true }}
                            />
                            {goal.status === 'complete' &&
                                <TextField style={{width: '49.5%'}}
                                    label="Completed Date"
                                    type="date"
                                    fullWidth
                                    value={goal.actual_completed_date.split('T')[0]}
                                    onChange={(e) => handleInputChange('actual_completed_date', new Date(e.target.value).toISOString())}
                                    InputLabelProps={{ shrink: true }}
                                />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth  >
                                <InputLabel id="progress-label">Progress (RAG)*</InputLabel>
                                <StyledSelect
                                    labelId="progress-label"
                                    label="Progress (RAG)*"
                                    variant="outlined"
                                    value={goal.rag || ""}
                                    onChange={(e) => handleInputChange('rag', e.target.value)}
                                >
                                    <MenuItem value={""}></MenuItem>
                                    <MenuItem value={"red"} style={{ backgroundColor: ragColors.red }}>Red</MenuItem>
                                    <MenuItem value={"amber"} style={{ backgroundColor: ragColors.amber }}>Amber</MenuItem>
                                    <MenuItem value={"green"} style={{ backgroundColor: ragColors.green }}>Green</MenuItem>
                                </StyledSelect>
                            </FormControl> 
                        </Grid>
                        {goal.original_target_date && goal.original_target_date !== goal.target_date && (
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" color="textSecondary">
                                    Original Target Date: {goal.original_target_date.split('T')[0]}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Paper>

                {/* {('reviews' in goal && goal.reviews.length !== 0) && <> */}
                    {/* Goal Reviews Section */}
                    <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 1 }}>
                        <Paper sx={{ p: 1 }}>
                            <Typography variant="h4" align="center">Progress Reviews</Typography>
                            {goal.reviews?.map((review, index) => (
                                <GoalReviewEntry
                                    key={index}
                                    review={review}
                                    remove={handleConfirmRemoveReview}
                                    setEditing={handleEditReview}
                                />
                            ))}
                        </Paper>
                    </Box>
                    {/* </>} */}

                {/* Confirm Delete Modal */}
                {confirmModal &&
                    <DeleteConfirmationBox
                        message={"Are you sure you want to delete this goal review?"}
                        handleConfirm={() => handleDeleteReview(idToDelete)}
                        handleCancel={cancelEntry}
                    />
            }
            </Box>
        </>}

        {state === 'view' &&
            <Fab color="secondary" aria-label="add" onClick={() => {setReviewHolder(emptyReview); setState('entering')}} style={{ position: 'absolute', bottom: '20px', right: '20px' }}>
                <AddIcon />
            </Fab>      
        }

        {(state === 'entering' || state === 'editing') && 
            <AddGoalReview reviewHolder={reviewHolder} handleSubmit={handleSubmitReview} handleCancel={cancelEntry} />
        }
    </>);
};

export default Goal;
