import { useNavigate } from 'react-router-dom';
import { IconButton } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';

const ConditioningTestSessionEntry = ({session, testTitle, remove}) => {

    const navigate = useNavigate();

    const handleParentClick = () => {
        navigate("/conditioning/test-session", { state: { session }});
    };

    const handleDeleteClick = (event) => {
        event.stopPropagation();
        remove(session.id);
    };


    return (
        <div className='entry' style={{cursor: 'pointer', position: 'relative', border: '2px solid white', borderRadius: '10px', padding: '5px', margin: '5px 0'}} onClick={handleParentClick}>
            <IconButton style={{position: 'absolute', top: '5px', right: '5px', padding: '0'}} aria-label="delete" size="large" alt='delete' onClick={handleDeleteClick}>
                <DeleteIcon color="black" fontSize="inherit" />
            </IconButton>

            <p>{new Date(session.test_date).toLocaleDateString('en-GB') + ' - ' + testTitle}</p>                
        </div>
    )
};

export default ConditioningTestSessionEntry;