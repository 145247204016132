import { useEffect, useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import AddIcon from '@mui/icons-material/Add'
import Header from "../../../../components/general/Header.tsx";
import AddConditioningTestExercise from "./AddConditioningTestExercise.js";

const CreateConditioningTest = ({ userId, submitHandler, handleCancel }) => {

    const [circuit, setCircuit] = useState({title: ''});
    const [exercises, setExercises] = useState([]);
    const [exerciseList, setExerciseList] = useState([]);
    const [addingExercise, setAddingExercise] = useState(false);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_HOST + `/api/conditioning/exercises/${userId}`)
        .then(response => response.json())
        .then(items => setExerciseList(items))
        .catch(error => console.error('Error fetching exercises:', error));
    }, [userId]);

    const handleFieldChange = (fieldName, e) => {
        if (e.target.value === '') {
            setCircuit({ ...circuit, [fieldName]: '' });
        } else {
            setCircuit({ ...circuit, [fieldName]: e.target.value });
        }
    };

    const handleNewExercise = (newExercise) => {
        setExercises([...exercises, newExercise]);
        setAddingExercise(false);
    }

    const preSubmit = () => {
        setCircuit((prev) => {
            const newCircuit = {
                ...prev,
                exercises: exercises
            };

            submitHandler(newCircuit);
            return newCircuit
        });
    }

    if (exerciseList.length === 0) {
        return (
            <p>Loading...</p>
        )
    }

    return (<>
        <Header title={'Create Circuit'} />

        <form className="new-entry">
            {/* circuit title */}
            <TextField fullWidth style={{marginTop: '5px'}}
                type="text"
                id="title"
                label="Title"
                variant="outlined"
                value={circuit.title || ''}
                onChange={(e) => handleFieldChange('title', e)}
            />     

            {exercises.map((exercise, index) => (
                <p key={index}>{(index + 1) + ', ' + exerciseList.filter(ex => ex.id === exercise.exercise_id)[0].name + (exercise.details ? ' (' + exercise.details + ')' : '')}</p>
            ))}

            {/* Add exercise button  */}
            <Box sx={{textAlign: 'center', marginTop: '10px', height: '33px', width: '100%'}}>
                <Button variant="contained" color="primary" style={{fontSize: '12px', color: '#fff'}} endIcon={<AddIcon />}  onClick={() => {setAddingExercise(true)}}>Add Exercise</Button>
            </Box>

            <div className='bottom-buttons'>
                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    disabled={!(circuit.title?.length && exercises?.length)}
                    onClick={preSubmit}
                    variant="contained"
                >
                    Save entry
                </Button>

                <Button
                    style={{margin: "10px 5px 5px", width: '100px'}}
                    onClick={handleCancel}
                    variant="contained"
                >
                    Cancel
                </Button>
            </div> 
        </form>

        {addingExercise && 
            <AddConditioningTestExercise exerciseList={exerciseList} handleSubmit={handleNewExercise} handleCancel={() => setAddingExercise(false)} />
        }
        
    </>)
};

export default CreateConditioningTest;